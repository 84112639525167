import InputVariables from "./InputVariables";
import RunWorkflow from "./RunWorkflow";

interface ControlsProps {
  readonly: boolean;
}

const Controls = ({ readonly }: ControlsProps) => {
  return (
    <div className="pointer-events-none absolute left-3 right-3 top-4 z-[1]  flex flex-row  justify-between">
      <InputVariables readonly={readonly} />
      <div className="flex flex-col gap-y-2">
        {!readonly && <RunWorkflow />}
      </div>
    </div>
  );
};

export default Controls;
