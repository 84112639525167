import { forwardRef } from "react";
import { Workflow } from "../types";
import Canvas from "./Canvas";
import Container from "./Container";
import Sidebar from "./Sidebar";
import { WorkflowProvider } from "./workflow-context";

interface LayoutProps extends Workflow {
  readonly?: boolean;
  userToken: string | null;
}

const WorkflowCanvas = forwardRef((props: LayoutProps, ref) => {
  const { readonly } = props;
  const version = props.nodes.length > 0 && props.nodes[0]?.workflow_version_id;
  const first_node_id = props.nodes.length > 0 && props.nodes[0]?.id;

  return (
    <WorkflowProvider
      ref={ref}
      workflow={{
        ...props,
        nodes: props.nodes.map((node) => ({ ...node, saved: true })),
      }}
      key={`${props.workflow_id}_${version}_${first_node_id}`}
    >
      <Container>
        <Canvas readonly={readonly} />
        {!readonly && <Sidebar />}
      </Container>
    </WorkflowProvider>
  );
});

export default WorkflowCanvas;
