import { useState, useEffect, useRef } from "react";

const useDynamicContainerHeight = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState("400px");

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const viewportHeight = window.innerHeight;
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const newHeight = viewportHeight - containerTop - 20; // 20px for some bottom padding
        setContainerHeight(`${newHeight}px`);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, [containerRef]);

  return [containerHeight, containerRef] as const;
};

export default useDynamicContainerHeight;
