import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import useHasWorkflowAccess from "@/hooks/useHasWorkflowAccess";
import {
  useAvailableReleaseLabels,
  useAvailableWorkflowReleaseLabels,
} from "@/queries";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { FC, useEffect, useMemo, useState } from "react";
import { Switch } from "./switch";

type SelectReleaseLabelModalProps = {
  isOpen: boolean;
  onSubmit: (promptLabelName: string, type: "prompt" | "workflow") => void;
  setOpen: (isOpen: boolean) => void;
  currentExperimentsCount: number;
};

const SelectReleaseLabelModal: FC<SelectReleaseLabelModalProps> = ({
  isOpen,
  onSubmit,
  setOpen,
  currentExperimentsCount,
}) => {
  const [selectedReleaseLabel, setSelectedReleaseLabel] = useState("");
  const [isPromptScope, setIsPromptScope] = useState(true);
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const userContext = useUser();
  const hasWorkflowAccess = useHasWorkflowAccess();
  const { data } = useAvailableReleaseLabels(
    userToken!,
    userContext.activeWorkspaceId!,
  );

  const { data: availableWorkflowLabels } = useAvailableWorkflowReleaseLabels(
    userToken!,
    userContext.activeWorkspaceId!,
  );

  useEffect(() => {
    setSelectedReleaseLabel(""); // reset selected release label on switch toggle
  }, [isPromptScope]);

  useEffect(() => {
    // Reset selected release label when modal is closed
    if (!isOpen) {
      setSelectedReleaseLabel("");
      setIsPromptScope(true);
    }
  }, [isOpen]);

  const availableReleaseLabelsList = useMemo(() => {
    if (!data) return [];
    return isPromptScope
      ? [...(data.available_prompt_label_names || [])].sort()
      : [
          ...(availableWorkflowLabels?.available_workflow_label_names || []),
        ].sort();
  }, [
    availableWorkflowLabels?.available_workflow_label_names,
    data,
    isPromptScope,
  ]);

  const handleSubmit = () => {
    try {
      onSubmit(selectedReleaseLabel, isPromptScope ? "prompt" : "workflow");
      setOpen(false);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const renderContent = () => {
    return (
      <div className="space-y-6">
        {hasWorkflowAccess && (
          <div className="flex flex-col space-y-2">
            <span className="text-sm font-medium text-gray-700">
              Select Scope:
            </span>
            <div className="flex items-center justify-between rounded-lg bg-gray-100 p-3">
              <span
                className={`text-sm transition-all duration-200 ${
                  isPromptScope ? "font-bold text-blue-600" : "text-gray-600"
                }`}
              >
                Prompt Template
              </span>
              <Switch
                checked={!isPromptScope}
                onChange={() => setIsPromptScope(!isPromptScope)}
                className="mx-3"
              />
              <span
                className={`text-sm transition-all duration-200 ${
                  !isPromptScope ? "font-bold text-blue-600" : "text-gray-600"
                }`}
              >
                Workflow
              </span>
            </div>
          </div>
        )}
        {availableReleaseLabelsList.length === 0 ? (
          <div className="flex flex-col items-center gap-y-2 rounded-lg border border-gray-100 bg-gray-50 p-4 text-center text-sm">
            <InformationCircleIcon className="h-6 w-6 text-gray-500" />
            <div>
              You do not have any available release labels for{" "}
              {isPromptScope ? "Prompt Templates" : "Workflows"}.
              <br />
              <a
                href="https://docs.promptlayer.com/features/prompt-registry/release-labels"
                className="text-blue-500 hover:text-blue-600"
              >
                Learn more about release labels on the docs.
              </a>
            </div>
          </div>
        ) : (
          <div className="space-y-2">
            <label
              htmlFor="release-label-select"
              className="block text-sm font-medium text-gray-700"
            >
              Release Label for {isPromptScope ? "Prompt Template" : "Workflow"}
            </label>
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-left shadow-sm transition-all duration-200 hover:border-blue-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                {selectedReleaseLabel || "Select a release label"}
              </DropdownMenuTrigger>
              <DropdownMenuContent className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                {availableReleaseLabelsList.map((releaseLabel) => (
                  <DropdownMenuItem
                    key={releaseLabel}
                    onSelect={() => setSelectedReleaseLabel(releaseLabel)}
                    className="cursor-pointer px-4 py-2 text-sm text-gray-700 transition-colors duration-150 hover:bg-blue-50 hover:text-blue-700"
                  >
                    {releaseLabel}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    );
  };

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Release Label</DialogTitle>
          <DialogDescription>
            <span className="max-w-md">
              Choose a release label to build an experiment on top of. The
              release label must already be configured on one of your prompt
              templates or workflows.
            </span>
          </DialogDescription>
        </DialogHeader>
        {renderContent()}
        <DialogFooter>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Cancel
          </Button>
          {availableReleaseLabelsList.length > 0 && (
            <Button onClick={handleSubmit} disabled={!selectedReleaseLabel}>
              Submit
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectReleaseLabelModal;
