import { useAuth } from "@/context/auth-context";
import { useDeleteWorkflow, useWorkflows } from "@/queries";
import { displayErrorToast } from "@/utils/toast";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteItem } from "../DeleteItem";
import { Workflow } from "../Workflows/types";
import Registry from "./Registry";
import WorkflowCard from "./WorkflowCard";

const WorkflowGrid: React.FC = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<Workflow | null>(null);
  const auth = useAuth();
  const userToken = auth?.userToken || "";
  const deleteWorkflow = useDeleteWorkflow(userToken);

  const renderCard = (
    item: Workflow,
    workspaceId: string,
    onDelete: (item: Workflow) => void,
  ): React.ReactNode => {
    return (
      <WorkflowCard
        workflow={item}
        workspaceId={workspaceId}
        onDelete={onDelete}
      />
    );
  };

  const handleDeleteTrigger = (item: Workflow): void => {
    setItemToDelete(item);
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = (): void => {
    if (itemToDelete) {
      deleteWorkflow.mutate(
        { workflow_id: itemToDelete.id },
        {
          onSuccess: () => {
            setIsDeleteOpen(false);
            setItemToDelete(null);
          },
          onError: () => {
            displayErrorToast("Error deleting workflow");
          },
        },
      );
    }
  };

  return (
    <>
      <Registry<Workflow>
        title={"Workflow"}
        subtitle={"Super charge your prompt engineering with workflows."}
        itemsKey={"workflows"}
        createButtonText={"Create Workflow"}
        createButtonLink={`/workspace/${workspaceId}/create-workflow`}
        useDataHook={useWorkflows}
        renderCard={renderCard}
        onDeleteItem={handleDeleteTrigger}
      />
      <DeleteItem
        open={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        setDelete={handleDeleteConfirm}
        itemName={itemToDelete?.name || ""}
        itemType={"workflow"}
      />
    </>
  );
};

export default WorkflowGrid;
