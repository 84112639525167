import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DeleteItem } from "@/components/DeleteItem";
import { ResourceHeader } from "@/components/ResourceHeader";
import { useAuth } from "@/context/auth-context";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WorkflowCanvas from "../WorkflowCanvas";
import { IWorkflow } from "../WorkflowCanvas/workflow-store";
import useDynamicContainerHeight from "./hooks/useDynamicContainerHeight";
import useHeaderActions from "./hooks/useHeaderActions";
import useWorkflowData from "./hooks/useWorkflowData";
import WorkflowExecutionTable from "./WorkflowExecutionTable";
import WorkflowVersionSelector from "./WorkflowVersionSelector";

interface WorkflowProps {
  editing?: boolean;
}

const Workflow = observer(({ editing }: WorkflowProps) => {
  const auth = useAuth();
  const userToken = auth?.userToken || "";
  const { workflowId, versionId: versionNumber, workspaceId } = useParams();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVersionList, setShowVersionList] = useState(!editing);

  const navigate = useNavigate();

  const [containerHeight, containerRef] = useDynamicContainerHeight();

  const workflowRef = useRef<IWorkflow | undefined>();
  const observableWorkflowRef = observable.box(workflowRef.current);
  const { workflow, versions, isLoading, refetchVersions } = useWorkflowData(
    {
      workflowId: workflowId!,
      versionNumber: versionNumber!,
      userToken: userToken,
      workspaceId: workspaceId!,
    },
    !!editing,
    navigate,
  );

  const actions = useHeaderActions(!!editing, workflowRef, () => {
    setShowDeleteModal(true);
  });

  const handleTitleEdit = (newTitle: string) => {
    if (observableWorkflowRef.get()) {
      runInAction(() => {
        observableWorkflowRef.get()?.setName(newTitle, true);
      });
    }
  };

  return (
    <>
      <div className="flex h-full flex-col p-1">
        <div className="pb-3">
          <Breadcrumbs
            items={
              [
                "Registry",
                "Workflows",
                observableWorkflowRef.get()?.name,
              ].filter(Boolean) as string[]
            }
            navigateUrl={`/workspace/${workspaceId}/workflow`}
          />
        </div>
        <ResourceHeader
          title={observableWorkflowRef.get()?.name || workflow.name}
          onTitleEdit={handleTitleEdit}
          actions={actions}
          id={workflow.workflow_id!}
          createdAt={workflow.created_at || ""}
          versionInfo={{
            current: Number(versionNumber) || 1,
            total: versions?.length || 0,
          }}
          isLoading={isLoading}
          showVersions={showVersionList}
          onToggleVersions={() => setShowVersionList(!showVersionList)}
        />
        <div
          ref={containerRef}
          className={`flex flex-grow flex-row gap-x-6 overflow-hidden pb-5 ${
            !editing && "max-h-full"
          }`}
          style={{ height: containerHeight }}
        >
          {showVersionList && versions && (
            <WorkflowVersionSelector
              versions={versions}
              workflowRef={workflowRef}
              showVersionList={showVersionList}
              setShowVersionList={setShowVersionList}
              refreshVersions={refetchVersions}
            />
          )}
          <div className={`flex h-full w-full flex-grow`}>
            <WorkflowCanvas
              ref={(ref) => {
                workflowRef.current = ref as IWorkflow;
                runInAction(() => observableWorkflowRef.set(ref as IWorkflow));
              }}
              readonly={!editing}
              {...workflow}
              userToken={userToken}
            />
          </div>
        </div>
      </div>
      {workflow.version_id && (
        <WorkflowExecutionTable workflowVersionId={workflow.version_id} />
      )}
      <DeleteItem
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        setDelete={() => {
          // Implement delete logic here
          workflowRef.current?.delete(() => {
            navigate(`/workspace/${workspaceId}/workflow`);
          });
          setShowDeleteModal(false);
        }}
        itemName={observableWorkflowRef.get()?.name || workflow.name}
        itemType="workflow"
      />
    </>
  );
});

export default Workflow;
