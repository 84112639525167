import {
  DropdownMenu,
  DropdownMenuBasicTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import FilterInput from "@/components/ui/table/Filter/FilterInput";
import { useColumnSearch } from "@/components/ui/table/Search/search-context";
import { useCollapsibleColumnContext } from "@/components/ui/table/collapsible-columns/useCollapsibleColumns";
import { useAuth } from "@/context/auth-context";
import { useDeleteReportColumn, usePatchReportColumn } from "@/queries";
import { ColumnType, ReportColumn } from "@/types/evaluate";
import {
  ExclamationIcon,
  EyeIcon,
  LinkIcon,
  PencilIcon,
  SparklesIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  FilterIcon,
} from "@heroicons/react/solid";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  EyeClosedIcon,
} from "@radix-ui/react-icons";
import React, { useCallback, useState } from "react";
import { SortConfigItem, useReportContext } from "../report-context";
import ModalRouter from "./NewColumnModal/ModalRouter";

interface HeaderCellProps {
  column: ReportColumn;
  reportId: number;
  availableColumns: ReportColumn[];
  isEditable: boolean;
}

const HeaderCell: React.FC<HeaderCellProps> = ({
  column,
  reportId,
  availableColumns,
  isEditable,
}) => {
  const { sortConfig, sortColumn } = useReportContext();

  const [enabled, showFilterInput, setShowFilterInput] = useColumnSearch(
    column?.id!.toString(),
  );

  const userToken = useAuth()?.userToken || "";
  const { mutate: patchColumn } = usePatchReportColumn(userToken, reportId);
  const handleMoveColumn = React.useCallback(
    (direction: "left" | "right") => {
      if (column.id && column.position !== undefined) {
        const newPosition =
          direction === "left" ? column.position - 1 : column.position + 1;
        column.position = newPosition;
        patchColumn(column);
      }
    },
    [column, patchColumn],
  );
  const { mutate: deleteColumn } = useDeleteReportColumn(userToken, reportId);
  const { toggleColumn } = useCollapsibleColumnContext();

  const handleDeleteColumn = React.useCallback(() => {
    if (column.id && column.id !== undefined) {
      if (window.confirm("Are you sure you want to delete this column?")) {
        deleteColumn(column.id);
      }
    }
  }, [column.id, deleteColumn]);

  const availablePreviousColumns = React.useMemo(
    () => availableColumns.filter((c) => c.position < column.position),
    [availableColumns, column.position],
  );

  const getIcon = (columnType: ColumnType) => {
    switch (columnType) {
      case ColumnType.ENDPOINT:
        return <LinkIcon className="mr-1 inline-block h-4 w-4 text-gray-600" />;
      case ColumnType.PROMPT_TEMPLATE:
        return (
          <SparklesIcon className="mr-1 inline-block h-4 w-4 text-gray-600" />
        );
      default:
        return null;
    }
  };

  const icon = React.useMemo(
    () => getIcon(column.column_type),
    [column.column_type],
  );

  const headerCell = React.useMemo(
    () => (
      <div
        className={`flex h-full w-full cursor-text items-center justify-center gap-x-1 text-center font-semibold text-gray-700`}
      >
        {icon}
        {`${column.name}`}
      </div>
    ),
    [icon, column.name],
  );

  const DatasetColumnCell = React.useCallback(
    () => (
      <div className="flex h-full w-full flex-row items-center justify-between">
        <div className="text-center font-mono">{`{${column.name}}`}</div>
      </div>
    ),
    [column.name],
  );

  const ErrorColumnCell = React.useCallback(
    () => (
      <div
        className={`flex h-full w-full flex-col items-center justify-center gap-y-2 rounded-md border border-red-300 p-2 hover:border-red-400 hover:bg-red-50 hover:shadow-sm`}
      >
        <div className="flex items-center">{headerCell}</div>
        <div className="rounded border border-red-500 bg-red-500 px-2 py-1 text-sm font-normal text-white">
          <ExclamationIcon className="mr-1 inline-block h-4 w-4" />
          {column.errors![0].message}
        </div>
      </div>
    ),
    [column.errors, headerCell],
  );

  const DefaultColumnCell = React.useCallback(
    () => (
      <div className="flex h-full w-full flex-row justify-between">
        <div
          className={`pointer-events-none flex h-full w-full flex-col items-start justify-center gap-y-2 `}
        >
          <div className=" flex items-start border-b-2 border-gray-500 ">
            {headerCell}
          </div>
        </div>
      </div>
    ),
    [headerCell],
  );

  const handleSort = useCallback(
    (direction: "asc" | "desc") => {
      return (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const isSorted = sortConfig.find(
          (item: SortConfigItem) => item.columnId === column.id,
        );
        if (isSorted && isSorted.direction === direction) {
          sortColumn(column.id, null);
        } else {
          sortColumn(column.id, direction);
        }
      };
    },
    [column.id, sortConfig, sortColumn],
  );

  const Cell = React.useCallback(() => {
    if (column.column_type === ColumnType.DATASET) {
      return <DatasetColumnCell />;
    } else if (column.errors) {
      return <ErrorColumnCell />;
    } else {
      return <DefaultColumnCell />;
    }
  }, [
    DatasetColumnCell,
    DefaultColumnCell,
    ErrorColumnCell,
    column.column_type,
    column.errors,
  ]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const onClose = () => setDialogOpen(false);

  return (
    <div className="relative h-full w-full select-none">
      <DropdownMenu>
        <DropdownMenuBasicTrigger>
          <Cell />
          <div className="absolute right-3 top-0 flex h-full w-full items-center justify-center">
            <div className="hidden  h-full w-full items-center justify-end group-hover/DropdownMenu:flex">
              <div className="rounded-md bg-white p-1 shadow-sm">
                <ChevronDownIcon className="h-4 w-4 text-gray-500" />
              </div>
            </div>
          </div>
        </DropdownMenuBasicTrigger>
        <DropdownMenuContent
          align="start"
          className={`${(dialogOpen && "hidden") || ""}`}
        >
          {!isEditable && column.column_type !== ColumnType.DATASET && (
            <DropdownMenuItem
              className="w-full"
              onSelect={(event) => {
                event.preventDefault();
                setDialogOpen(true);
              }}
            >
              <ModalRouter
                availableColumns={availablePreviousColumns}
                reportId={reportId}
                oldColumn={column}
                editable={false}
                onClose={onClose}
              >
                <div className="flex w-full items-center">
                  <EyeIcon className="mr-2 inline-block h-4 w-4" />
                  Inspect
                </div>
              </ModalRouter>
            </DropdownMenuItem>
          )}
          {enabled && (
            <>
              <DropdownMenuItem
                className="w-full"
                onSelect={(event) => {
                  event.preventDefault();
                  setShowFilterInput(true);
                }}
              >
                <div className="flex items-center">
                  <FilterIcon className="mr-2 inline-block h-4 w-4" />
                  Filter
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="w-full"
                onSelect={(event) => handleSort("asc")}
              >
                <div className="flex items-center">
                  <ArrowUpIcon className="mr-2 inline-block h-4 w-4" />
                  Sort ascending
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="w-full"
                onSelect={(event) => handleSort("desc")}
              >
                <div className="flex items-center">
                  <ArrowDownIcon className="mr-2 inline-block h-4 w-4" />
                  Sort descending
                </div>
              </DropdownMenuItem>
            </>
          )}
          {isEditable && (
            <DropdownMenuItem
              className="w-full"
              onSelect={(event) => {
                event.preventDefault();
                setDialogOpen(true);
              }}
            >
              <ModalRouter
                availableColumns={availablePreviousColumns}
                reportId={reportId}
                onClose={onClose}
                oldColumn={column}
              >
                <div className="flex w-full items-center">
                  <PencilIcon className="mr-2 inline-block h-4 w-4" />
                  Edit
                </div>
              </ModalRouter>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            className="w-full"
            onSelect={(event) => {
              event.preventDefault();
              toggleColumn(column.id);
            }}
          >
            <div className="flex items-center">
              <EyeClosedIcon className="mr-2 inline-block h-4 w-4" />
              Hide
            </div>
          </DropdownMenuItem>

          {isEditable && (
            <DropdownMenuItem
              className="w-full"
              onSelect={(event) => {
                event.preventDefault();
                handleDeleteColumn();
              }}
            >
              <div className="flex items-center">
                <XIcon className="mr-2 inline-block h-4 w-4 text-red-500" />
                Delete
              </div>
            </DropdownMenuItem>
          )}

          {isEditable && (
            <>
              {column &&
                availablePreviousColumns.length > 0 &&
                availablePreviousColumns[availablePreviousColumns.length - 1]
                  .column_type !== ColumnType.DATASET && (
                  <DropdownMenuItem
                    className="w-full"
                    onSelect={(event) => {
                      event.preventDefault();
                      handleMoveColumn("left");
                    }}
                  >
                    <div className="flex items-center">
                      <ArrowLeftIcon className="mr-2 inline-block h-4 w-4" />
                      Move left
                    </div>
                  </DropdownMenuItem>
                )}
              {availableColumns.length > 0 &&
                availableColumns.indexOf(column) <
                  availableColumns.length - 1 && (
                  <DropdownMenuItem
                    className="w-full"
                    onSelect={(event) => {
                      event.preventDefault();
                      handleMoveColumn("right");
                    }}
                  >
                    <div className="flex items-center">
                      <ArrowRightIcon className="mr-2 inline-block h-4 w-4" />
                      Move right
                    </div>
                  </DropdownMenuItem>
                )}
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {showFilterInput && (
        <FilterInput
          column={column}
          onClose={() => setShowFilterInput(false)}
        />
      )}
    </div>
  );
};

export default HeaderCell;
