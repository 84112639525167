import { ResourceHeader } from "@/components/ResourceHeader";
import { useNavigate, useParams } from "react-router-dom";
import WorkflowCanvas from "../WorkflowCanvas";

import { Breadcrumbs } from "@/components/Breadcrumbs";

import SaveWithCommitButton from "@/components/SaveWithCommitButton";
import { useAuth } from "@/context/auth-context";
import { useWorkflowEditorState } from "@/queries";
import { XIcon } from "@heroicons/react/solid";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";
import { IWorkflow } from "../WorkflowCanvas/workflow-store";
const workflowRegistry = {
  id: -1,
  created_at: "",
  workflow_name: "Untitled Workflow",
  version_number: 1,
  total: 1,
};

const useNewWorkflowVersion = () => {
  const auth = useAuth();
  const userToken = auth?.userToken || "";
  const { workspaceId } = useParams();
  const { data, isLoading } = useWorkflowEditorState(
    Number(workspaceId)!,
    userToken,
  );
  return { data, isLoading };
};
const CreateWorkflow = () => {
  const { workspaceId } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);

  const [showVersionList, setShowVersionList] = React.useState(false);
  const workflowRef = useRef<IWorkflow>();
  const observableWorkflowRef = observable.box(workflowRef.current);

  const auth = useAuth();
  const userToken = auth?.userToken;

  const navigate = useNavigate();

  const { data: newWorkflowData, isLoading: isNewWorkflowLoading } =
    useNewWorkflowVersion();

  const actions = [
    {
      label: "Cancel",
      icon: <XIcon className="h-4 w-auto pr-1" aria-hidden="true" />,
      onClick: () => navigate(`/workspace/${workspaceId}/workflow`),
      variant: "destructiveOutline" as const,
    },
    <SaveWithCommitButton
      name="Create Workflow"
      onSave={async (commitMessage) => {
        try {
          setIsLoading(true);
          const response = await workflowRef.current?.save(commitMessage);
          if (response && response.success) {
            navigate(
              `/workspace/${workspaceId}/workflow/${response.workflow_id}`,
            );
          }
          setIsLoading(false);
        } catch (error) {
          // Handle any errors
          setIsLoading(false);
          console.error("Error saving workflow:", error);
        }
      }}
    />,
  ];

  const defaultWorkflowName = useMemo(
    () =>
      `Workflow ${Array(6)
        .fill(0)
        .map(() => String.fromCharCode(65 + Math.floor(Math.random() * 26)))
        .join("")}`,
    [],
  );

  const handleTitleEdit = (newTitle: string) => {
    if (observableWorkflowRef.get()) {
      runInAction(() => {
        observableWorkflowRef.get()?.setName(newTitle);
      });
    }
  };

  const defaultWorkflow = {
    workflow_id: -1,
    workspace_id: Number(workspaceId)!,
    commit_message: "",
    nodes: newWorkflowData?.workflow_nodes || [],
    input_variables:
      (localStorage.getItem("inputVariables") &&
        JSON.parse(localStorage.getItem("inputVariables")!)) ||
      newWorkflowData?.workflow_version.required_input_variables?.reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {},
      ) ||
      {},
    userToken: userToken!,
    id: -1,
    ...newWorkflowData?.workflow,
    name: defaultWorkflowName,
    version_id: newWorkflowData?.workflow_version.id,
  };

  const workflowName =
    observableWorkflowRef.get()?.name || defaultWorkflow.name || "Loading...";

  return (
    <>
      <div className="flex h-full flex-col p-1">
        <div className="pb-3">
          <Breadcrumbs
            items={
              ["Registry", "Workflows", workflowName].filter(
                Boolean,
              ) as string[]
            }
            navigateUrl={`/workspace/${workspaceId}/workflow`}
          />
        </div>
        <ResourceHeader
          title={workflowName}
          onTitleEdit={handleTitleEdit}
          actions={actions}
          id={workflowRegistry.id!}
          createdAt={workflowRegistry.created_at}
          versionInfo={{
            current: workflowRegistry.version_number || 1,
            total: workflowRegistry.total,
          }}
          isLoading={isLoading}
          showVersions={showVersionList}
          onToggleVersions={() => setShowVersionList(!showVersionList)}
        />
        <div className={`flex flex-grow flex-row gap-x-6 overflow-hidden pb-5`}>
          <div
            className={`flex h-full w-full flex-grow transition-opacity duration-200 ease-in-out ${
              isLoading ? "opacity-50" : "opacity-100"
            }`}
          >
            {!isNewWorkflowLoading && (
              <WorkflowCanvas
                ref={(ref) => {
                  workflowRef.current = ref as IWorkflow;
                  runInAction(() =>
                    observableWorkflowRef.set(ref as IWorkflow),
                  );
                }}
                {...defaultWorkflow}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(CreateWorkflow);
