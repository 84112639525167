import { Edge, Node } from "@xyflow/react";
import { WorkflowNode, WorkflowNodeStatus } from "./types";

const EDGE_COLORS = {
  COMPLETED: "#10b981", // Green for completed
  RUNNING: "#3b82f6", // Blue for running
  QUEUED: "#fbbf24", // Yellow for queued
  DEFAULT: "#ddd", // Light gray for default/unknown states
};

export const getEdgeColor = (type: WorkflowNodeStatus | undefined): string =>
  EDGE_COLORS[type?.toUpperCase() as keyof typeof EDGE_COLORS] ||
  EDGE_COLORS.DEFAULT;

function generateEdgesFromNodes(nodes: WorkflowNode[]): Edge[] {
  return nodes.flatMap((node) =>
    node.dependencies.map((dependencyId) => ({
      id: `${dependencyId}-${node.id}`,
      source: dependencyId,
      target: node.id,
    })),
  );
}

function positionNodes(sortedNodes: WorkflowNode[]): Node[] {
  const LEVEL_WIDTH: number = 350;
  const NODE_HEIGHT: number = 200;
  const NODE_WIDTH: number = 300;
  const MARGIN: number = 300;

  const calculateNodeLevels = (
    nodes: WorkflowNode[],
  ): Record<string, number> => {
    const nodeLevels: Record<string, number> = {};
    const visited: Set<string> = new Set();

    const calculateLevel = (nodeId: string): number => {
      if (visited.has(nodeId)) return nodeLevels[nodeId];
      visited.add(nodeId);

      const node = nodes.find((n) => n.id === nodeId);
      if (!node) return 0;

      if (node.dependencies.length === 0) {
        nodeLevels[nodeId] = 0;
      } else {
        const maxDependencyLevel = Math.max(
          ...node.dependencies.map(calculateLevel),
        );
        nodeLevels[nodeId] = maxDependencyLevel + 1;
      }

      return nodeLevels[nodeId];
    };

    nodes.forEach((node) => {
      if (!visited.has(node.id)) {
        calculateLevel(node.id);
      }
    });

    return nodeLevels;
  };

  const groupNodesByLevel = (
    nodes: WorkflowNode[],
    levels: Record<string, number>,
  ): Record<number, WorkflowNode[]> => {
    return nodes.reduce(
      (acc, node) => {
        const level = levels[node.id];
        if (!acc[level]) acc[level] = [];
        acc[level].push(node);
        return acc;
      },
      {} as Record<number, WorkflowNode[]>,
    );
  };

  const calculatePositions = (
    nodesByLevel: Record<number, WorkflowNode[]>,
  ): Record<string, { x: number; y: number }> => {
    const positions: Record<string, { x: number; y: number }> = {};
    const levelHeights: Record<number, number> = {};

    Object.entries(nodesByLevel).forEach(([level, nodes]) => {
      const numNodes = nodes.length;
      const levelHeight = numNodes * NODE_HEIGHT + (numNodes - 1) * MARGIN;
      levelHeights[Number(level)] = levelHeight;
    });

    const maxHeight = Math.max(...Object.values(levelHeights));

    Object.entries(nodesByLevel).forEach(([level, nodes]) => {
      const levelHeight = levelHeights[Number(level)];
      const x = Number(level) * (LEVEL_WIDTH + MARGIN) + NODE_WIDTH / 2;
      const startY = -maxHeight / 2 + (maxHeight - levelHeight) / 2;

      nodes.forEach((node, index) => {
        const y = startY + index * (NODE_HEIGHT + MARGIN) + NODE_HEIGHT / 2;
        if (!isNaN(x) && !isNaN(y)) {
          positions[node.id] = { x, y };
        } else {
          console.error(`Invalid position calculated for node ${node.id}`, {
            level,
            index,
            startY,
          });
        }
      });
    });

    return positions;
  };

  const nodeLevels = calculateNodeLevels(sortedNodes);
  const nodesByLevel = groupNodesByLevel(sortedNodes, nodeLevels);
  const positions = calculatePositions(nodesByLevel);

  return sortedNodes.map((node) => ({
    id: node.id,
    type: "basic",
    position: positions[node.id] || { x: 0, y: 0 },
    data: {
      label: node.name,
      id: node.id,
      type: node.configuration?.function_name?.toUpperCase() || node.node_type,
    },
  }));
}

const getAllLinkedDependencies = <
  T extends { dependencies?: string[]; name: string },
>(
  getNodeById: (id: string) => T | undefined,
  nodeId: string,
): string[] => {
  const node = getNodeById(nodeId);
  if (!node?.dependencies) return [];
  return [
    ...node.dependencies.map((depId) => getNodeById(depId)?.name || ""),
    ...node.dependencies.flatMap((dep) =>
      getAllLinkedDependencies(getNodeById, dep),
    ),
  ].filter(Boolean);
};

const withConfigurationNameToIdRemapping = (
  configuration: any,
  nodes: WorkflowNode[],
): any => {
  const remapValue = (value: any): any => {
    if (typeof value === "string") {
      const node = nodes.find((node) => node.name === value);
      return node ? node.id : value;
    } else if (Array.isArray(value)) {
      return value.map(remapValue);
    } else if (typeof value === "object" && value !== null) {
      return Object.fromEntries(
        Object.entries(value).map(([k, v]) => [k, remapValue(v)]),
      );
    }
    return value;
  };

  return remapValue(configuration);
};

const withConfigurationIdToNameRemapping = (
  configuration: any,
  nodes: WorkflowNode[],
): any => {
  const remapValue = (value: any): any => {
    if (typeof value === "string") {
      const node = nodes.find((node) => node.id === value);
      return node ? node.name : value;
    } else if (Array.isArray(value)) {
      return value.map(remapValue);
    } else if (typeof value === "object" && value !== null) {
      return Object.fromEntries(
        Object.entries(value).map(([k, v]) => [k, remapValue(v)]),
      );
    }
    return value;
  };

  return remapValue(configuration);
};

const nodeHasSource = (node: WorkflowNode, source_id: string): boolean => {
  const configuration = node.configuration;
  return (
    configuration.source === source_id ||
    configuration.first_source === source_id ||
    configuration.second_source === source_id ||
    configuration.sources?.includes(source_id) ||
    configuration.column === source_id ||
    configuration.first_column === source_id ||
    configuration.second_column === source_id ||
    configuration.target_column === source_id
  );
};

const withNameToIdRemapping = (nodes: WorkflowNode[]): WorkflowNode[] => {
  return nodes.map((node) => {
    const { dependencies } = node;
    const getAssociatedNode = (name: string): WorkflowNode | undefined =>
      nodes.find((node) => node.name === name);

    return {
      ...node,
      configuration: withConfigurationNameToIdRemapping(
        node.configuration || {},
        nodes,
      ),
      dependencies: dependencies.map(
        (dep) => getAssociatedNode(dep)?.id || dep,
      ),
    };
  });
};

const withIdToNameRemapping = (nodes: WorkflowNode[]): WorkflowNode[] =>
  nodes.map((node) => {
    const { dependencies } = node;
    const getAssociatedNode = (id: string): WorkflowNode | undefined =>
      nodes.find((node) => node.id === id);

    return {
      ...node,
      configuration: withConfigurationIdToNameRemapping(
        node.configuration || {},
        nodes,
      ),
      dependencies: dependencies.map(
        (dep) => getAssociatedNode(dep)?.name || dep,
      ),
    };
  });

const withIdToNameRemappingForOneNode = (
  node: WorkflowNode,
  nodes: WorkflowNode[],
): WorkflowNode => {
  const { dependencies } = node;
  const getAssociatedNode = (id: string): WorkflowNode | undefined =>
    nodes.find((n) => n.id === id);

  return {
    ...node,
    configuration: withConfigurationIdToNameRemapping(
      node.configuration || {},
      nodes,
    ),
    dependencies: dependencies.map(
      (dep) => getAssociatedNode(dep)?.name || dep,
    ),
  };
};

const formatRawNodeType = (value: string): string =>
  value
    .replaceAll("_", " ")
    .toLowerCase()
    .replace(/\b\w/g, (c) => c.toUpperCase());

const withEvalsCompatibilityLayer = (nodes: WorkflowNode[]): WorkflowNode[] =>
  nodes.map((node) => {
    const { configuration } = node;
    if (configuration.target_column) {
      configuration.source = configuration.target_column;
      delete configuration.column;
      delete configuration.target_column;
    }
    if (configuration.value || configuration.secondColumn) {
      if (configuration.secondColumn) {
        configuration.second_source = configuration.secondColumn;
        delete configuration.secondColumn;
      }
      configuration.source = configuration.column;
      configuration.first_source = configuration.column;
      delete configuration.column;
    } else if (configuration.column) {
      configuration.source = configuration.column;
      delete configuration.column;
    } else if (configuration.column_names) {
      [configuration.first_source, configuration.second_source] =
        configuration.column_names;
      configuration.sources = configuration.column_names;
      delete configuration.column_names;
    } else if (configuration.first_column && configuration.second_column) {
      configuration.first_source = configuration.first_column;
      configuration.second_source = configuration.second_column;
      delete configuration.first_column;
      delete configuration.second_column;
    }
    return node;
  });

const withoutEvalsCompatibilityLayer = (
  nodes: WorkflowNode[],
): WorkflowNode[] =>
  [...nodes].map((node) => {
    const { configuration } = node;
    if (configuration.value !== undefined && configuration.value !== null) {
      configuration.column = configuration.first_source;
    } else if (configuration.source) {
      if (configuration.regex_pattern) {
        configuration.target_column = configuration.source;
      } else {
        configuration.column = configuration.source;
      }
      delete configuration.source;
    } else if (configuration.sources) {
      configuration.column_names = configuration.sources;
      configuration.first_column = configuration.sources[0];
      configuration.second_column = configuration.sources[1];
      delete configuration.sources;
    } else if (configuration.first_source && configuration.second_source) {
      configuration.column_names = [
        configuration.first_source,
        configuration.second_source,
      ];
      configuration.first_column = configuration.first_source;
      configuration.second_column = configuration.second_source;
      configuration.secondColumn = configuration.second_source;
      configuration.column = configuration.first_source;
      delete configuration.first_source;
      delete configuration.second_source;
    }
    return node;
  });

export {
  generateEdgesFromNodes,
  positionNodes,
  formatRawNodeType,
  getAllLinkedDependencies,
  withEvalsCompatibilityLayer,
  withoutEvalsCompatibilityLayer,
  withIdToNameRemapping,
  withNameToIdRemapping,
  withIdToNameRemappingForOneNode,
  withConfigurationIdToNameRemapping,
  nodeHasSource,
};
