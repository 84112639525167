import { useRef } from "react";
import { CopyButton } from "./copy-button";

const useCopyButton = (ref: React.RefObject<HTMLDivElement>) => {
  return ref.current?.textContent ? (
    <CopyButton
      className="absolute right-4 top-3 hidden rounded-md bg-white p-1 shadow-lg group-hover:flex"
      text={ref.current?.textContent || ""}
    />
  ) : null;
};

const Callout = ({
  children,
  copy = true,
  className,
  ...props
}: {
  children: string | React.ReactNode;
  className?: string;
  copy?: boolean;
  [key: string]: any;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const CopyButton = useCopyButton(ref);
  return (
    <div className="group pointer-events-auto relative">
      {copy && CopyButton}
      <div
        className={`${className} mb-4 mt-2 max-h-[250px] overflow-y-auto rounded-md border bg-gray-50 px-2 py-4`}
        {...props}
        ref={ref}
      >
        <div className="flex">
          <div className="ml-3 text-sm font-medium text-blue-800">
            <p className="inline">{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
const CalloutWithTitle = ({
  children,
  title,
  titleSize = "text-lg",
  ...props
}: {
  children: React.ReactNode;
  title: string;
  titleSize?: "text-sm" | "text-base" | "text-lg" | "text-xl" | "text-2xl";
  [key: string]: any;
}) => {
  return (
    <>
      <h4 className={`mb-2 font-semibold text-gray-700 ${titleSize}`}>
        {title}
      </h4>
      <Callout {...props}>{children}</Callout>
    </>
  );
};

export { CalloutWithTitle };

export default Callout;
