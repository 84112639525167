import { OnNodesChange, NodeChange } from "@xyflow/react";
import { WorkflowNodeType } from "../../../types";

import { useWorkflow } from "../../workflow-context";
import { useDeleteWorkflowNode } from "@/queries";

const useStateMirroring = (onNodesChange: OnNodesChange) => {
  const workflow = useWorkflow();
  const { isSidebarOpen, activeNodeName } = workflow;
  const { mutate: deleteWorkflowNode } = useDeleteWorkflowNode(
    workflow.userToken!,
  );
  return (changes: NodeChange[]) => {
    changes.forEach((change) => {
      switch (change.type) {
        case "remove":
          deleteWorkflowNode(change.id!, {
            onSuccess: () => {
              workflow.removeNode(change.id);
              if (activeNodeName === change.id) {
                workflow.removeActiveNode();
                workflow.closeSidebar();
              }
            },
          });
          break;

        case "add":
          const newNode = workflow.addNode({
            id: change.item.id,
            name: change.item.data.label as string,
            node_type: change.item.data.type as WorkflowNodeType,
            configuration: {},
            dependencies: [],
            workflow_version_id: workflow.version_id!,
            is_output_node: false,
          });

          workflow.setActiveNode(newNode);
          if (!isSidebarOpen) workflow.openSidebar();
          break;
        case "replace":
          const prev = workflow.getNodeById(change.item.id);
          workflow.replaceNode(change.item.data.label as string, {
            name: change.item.data.label as string,
            node_type: change.item.data.type as WorkflowNodeType,
            status: prev?.status,
            configuration: prev?.configuration || {},
            dependencies: prev?.dependencies || [],
            is_output_node: prev?.is_output_node || false,
            workflow_version_id: workflow.version_id,
            saved: !change.item.data.unsaved,
          });
          break;
      }
    });
    return onNodesChange(changes);
  };
};

export default useStateMirroring;
