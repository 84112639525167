import { DivButton } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { ToastType } from "@/enums";
import { useEditInputVariables } from "@/queries";
import { displayToast } from "@/utils/toast";
import { CurlyBracesIcon, PlusCircleIcon, Trash2Icon } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useWorkflow } from "../../workflow-context";

interface InputVariableItemProps {
  id: string;
  keyProp: string;
  value: string;
  onUpdate: (id: string, field: "key" | "value", newValue: string) => void;
  onRemove: (id: string) => void;
}

const InputVariableItem = observer(
  ({ id, keyProp, value, onUpdate, onRemove }: InputVariableItemProps) => {
    return (
      <div className="flex items-center space-x-4 rounded-lg border border-gray-200 bg-gray-50 p-4 shadow-sm transition-all duration-200 hover:border-blue-300 hover:bg-blue-50">
        <Input
          type="text"
          value={keyProp}
          onChange={(e) => onUpdate(id, "key", e.target.value)}
          placeholder="Variable name"
          className="w-1/3 rounded-md border-gray-300 bg-white p-2 font-mono text-sm text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
        />
        <Input
          type="text"
          value={value}
          onChange={(e) => onUpdate(id, "value", e.target.value)}
          placeholder="Variable value"
          className="flex-1 rounded-md border-gray-300 bg-white p-2 font-mono text-sm text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
        />
        <DivButton
          variant="ghost"
          onClick={() => onRemove(id)}
          className="rounded-full p-2 text-gray-500 hover:bg-red-100 hover:text-red-600"
        >
          <Trash2Icon size={20} />
        </DivButton>
      </div>
    );
  },
);

interface InputVariableListProps {
  variables: Array<{ id: string; key: string; value: string }>;
  onUpdate: (id: string, field: "key" | "value", newValue: string) => void;
  onRemove: (id: string) => void;
}

const InputVariableList = observer(
  ({ variables, onUpdate, onRemove }: InputVariableListProps) => {
    return (
      <div
        className="max-h-[300px] space-y-4 overflow-y-auto"
        ref={(ref) => {
          if (ref) {
            ref.scrollTop = ref.scrollHeight;
          }
        }}
      >
        {variables.map(({ id, key, value }) => (
          <InputVariableItem
            key={id}
            id={id}
            keyProp={key}
            value={value}
            onUpdate={onUpdate}
            onRemove={onRemove}
          />
        ))}
      </div>
    );
  },
);

const EmptyVariableState = () => (
  <div className="flex h-[200px] items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50">
    <p className="text-center text-gray-500">
      No input variables defined. Add some to get started!
    </p>
  </div>
);

const InputVariableConfigurator = observer(() => {
  const workflow = useWorkflow();
  const [inputVariables, setInputVariables] = useState<
    Array<{ id: string; key: string; value: string }>
  >(() =>
    Object.entries(workflow.input_variables).map(([key, value]) => ({
      id: uuidv4(),
      key,
      value,
    })),
  );
  const [isSaving, setIsSaving] = useState(false);
  const editInputVariables = useEditInputVariables(
    workflow.userToken!,
    workflow.workspace_id!,
  );

  const handleAddVariable = () => {
    const newVariable = {
      id: uuidv4(),
      key: `var_${inputVariables.length + 1}`,
      value: "",
    };
    setInputVariables((prev) => [...prev, newVariable]);
  };

  const handleUpdateVariable = (
    id: string,
    field: "key" | "value",
    newValue: string,
  ) => {
    setInputVariables((prev) =>
      prev.map((variable) =>
        variable.id === id ? { ...variable, [field]: newValue } : variable,
      ),
    );
  };

  const handleRemoveVariable = (id: string) => {
    setInputVariables((prev) => prev.filter((variable) => variable.id !== id));
  };

  const handleSaveChanges = () => {
    setIsSaving(true);
    const variablesObject = inputVariables.reduce(
      (acc, { key, value }) => ({ ...acc, [key]: value }),
      {},
    );
    localStorage.setItem("inputVariables", JSON.stringify(variablesObject));

    editInputVariables.mutate(
      {
        input_variables: variablesObject,
      },
      {
        onSuccess: () => {
          workflow.updateInputVariables(variablesObject);
          displayToast("Successfully saved input variables", ToastType.success);
          setIsSaving(false);
        },
        onError: () => {
          setIsSaving(false);
        },
      },
    );
  };

  return (
    <div className="h-full w-full overflow-auto rounded-lg bg-white p-6 shadow-sm">
      <h2 className="mb-2 text-2xl font-semibold text-gray-800">
        Configure Input Variables
      </h2>
      <p className="mb-6 text-sm text-gray-600">
        Note: Only variable names are saved. Values are only used at runtime
        preview.
      </p>
      {inputVariables.length > 0 ? (
        <>
          <div className="mb-4 flex items-center justify-between">
            <div className="text-lg font-semibold text-gray-800">
              Input Variables ({inputVariables.length})
            </div>
          </div>
          <div className="mb-2 grid grid-cols-3 gap-4 rounded-t-lg bg-gray-100 p-3 text-sm font-medium text-gray-700">
            <div>Name</div>
            <div className="col-span-2">Value (for runtime preview)</div>
          </div>
          <InputVariableList
            variables={inputVariables}
            onUpdate={handleUpdateVariable}
            onRemove={handleRemoveVariable}
          />
          <p className="mt-4 rounded-lg bg-blue-50 p-3 text-sm text-blue-700">
            <span className="font-medium">Note:</span> Values are for runtime
            preview only and will not be saved with the workflow.
          </p>
        </>
      ) : (
        <EmptyVariableState />
      )}
      <div className="mt-6 flex items-center justify-between">
        <DivButton
          onClick={handleAddVariable}
          variant={"outline"}
          className="select-none"
        >
          <PlusCircleIcon size={18} className="mr-2" />
          Add Variable
        </DivButton>
        <div className="flex items-center space-x-2">
          <DivButton
            onClick={handleSaveChanges}
            variant={"default"}
            className="select-none"
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save Variable Names"}
          </DivButton>
        </div>
      </div>
    </div>
  );
});

interface InputVariablesProps {
  readonly: boolean;
}

const VariableCount = ({ count }: { count: number }) => (
  <div className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-[10px] text-white">
    {count}
  </div>
);

const TooltipVariableList = ({
  variables,
}: {
  variables: Record<string, string>;
}) => (
  <div className="max-h-[150px] overflow-y-auto">
    {Object.entries(variables).map(([key, value], i) => (
      <div key={`Variable_${i}`}>
        <span className="font-bold">
          {key}
          {(value && ":") || ""}
        </span>{" "}
        {value}
      </div>
    ))}
  </div>
);

const ReadOnlyVariableList = ({
  variables,
}: {
  variables: Record<string, string>;
}) => (
  <div className="max-h-[300px] space-y-4 overflow-y-auto">
    {Object.entries(variables).map(([key, value]) => (
      <div
        key={key}
        className="flex items-center space-x-4 rounded-lg border border-gray-200 bg-gray-50 p-4 shadow-sm"
      >
        <div className="w-1/3 font-mono text-sm text-gray-800">{key}</div>
        <div className="flex-1 font-mono text-sm text-gray-500">{value}</div>
      </div>
    ))}
  </div>
);

const InputVariables = observer(({ readonly }: InputVariablesProps) => {
  const { input_variables } = useWorkflow();
  const variableCount = Object.keys(input_variables).length;

  return (
    <Dialog>
      <TooltipProvider>
        <Tooltip defaultOpen={false}>
          <TooltipTrigger asChild>
            <DialogTrigger className="pointer-events-auto relative flex h-[36px] w-[36px] items-center justify-center rounded-md border-gray-100 bg-white shadow-md transition-colors duration-200 hover:bg-gray-50">
              <CurlyBracesIcon className="h-4 w-4 text-gray-600" />
              {variableCount > 0 && <VariableCount count={variableCount} />}
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent side="bottom">
            {variableCount > 0 ? (
              <TooltipVariableList variables={input_variables} />
            ) : (
              <div>No input variables defined</div>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent className="w-full max-w-2xl">
        {readonly ? (
          <div className="h-full w-full overflow-auto rounded-lg bg-white p-6 shadow-sm">
            <h2 className="mb-2 text-2xl font-semibold text-gray-800">
              Input Variables
              <span className="ml-2 text-sm font-normal text-gray-500">
                (Read-only)
              </span>
            </h2>
            <p className="mb-6 text-sm text-gray-600">
              These are the input variables defined for this workflow.
            </p>
            {variableCount > 0 ? (
              <>
                <div className="mb-2 grid grid-cols-3 gap-4 rounded-t-lg bg-gray-100 p-3 text-sm font-medium text-gray-700">
                  <div>Name</div>
                </div>
                <ReadOnlyVariableList variables={input_variables} />
              </>
            ) : (
              <EmptyVariableState />
            )}
          </div>
        ) : (
          <InputVariableConfigurator />
        )}
      </DialogContent>
    </Dialog>
  );
});

export default InputVariables;
