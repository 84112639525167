import { MobxMutation } from "@/queries/mobx/mobx-mutation";
import { QueryClient } from "@tanstack/react-query";
import { ENDPOINTS } from "@/api/application-api";
import { jsonAuthHeaders } from "@/utils/headers";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { SaveWorkflowMutationResponse, WorkflowNode } from "../types";

export const createSaveMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    SaveWorkflowMutationResponse,
    unknown,
    { is_existing: boolean; workflowSchema: any }
  >(
    {
      mutationFn: async (variables) => {
        const response = await fetch(`${ENDPOINTS.workflow_versions}`, {
          method: "POST",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({
            ...variables.workflowSchema,
            ...(variables.is_existing ? { name: undefined } : {}),
            is_existing: variables.is_existing,
          }),
        });
        return handleResponse(response);
      },
    },
    queryClient,
  );

export const createUpdateWorkflowNameMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    {
      success: boolean;
      workflow: {
        id: number;
        is_deleted: boolean;
        is_draft: boolean;
        name: string;
        workspace_id: number;
      };
    },
    unknown,
    { id: number; name: string }
  >(
    {
      mutationFn: async ({ id, name }) => {
        const response = await fetch(`${ENDPOINTS.workflows}/${id}`, {
          method: "PATCH",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({ name }),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error updating workflow");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflows]);
      },
      onError: (error) => {
        console.log("Error updating workflow", error);
      },
    },
    queryClient,
  );

export const createExecuteWorkflowMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    any,
    unknown,
    { workflow_version_id: number; input_variables: Record<string, string> }
  >(
    {
      mutationFn: async ({ workflow_version_id, input_variables }) => {
        const response = await fetch(
          `${ENDPOINTS.workflow_versions}/${workflow_version_id}/run`,
          {
            method: "POST",
            headers: jsonAuthHeaders(userToken),
            body: JSON.stringify({
              input_variables,
            }),
          },
        );
        return handleResponse(response);
      },
    },
    queryClient,
  );

export const createSaveSingleNodeMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    {
      success: boolean;
      message?: string;
      error?: string;
    },
    unknown,
    WorkflowNode
  >(
    {
      mutationFn: async (body: WorkflowNode) => {
        const response = await fetch(ENDPOINTS.workflow_nodes, {
          method: "POST",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify(body),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error(res.message || res.error || "");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflow_nodes]);
      },
      onError: (error) => {
        console.log("Error creating node", error);
      },
    },
    queryClient,
  );
export const createDeleteWorkflowMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    {
      success: boolean;
      message?: string;
      error?: string;
    },
    unknown,
    { workflow_id: number }
  >(
    {
      mutationFn: async ({ workflow_id }) => {
        console.log("Deleting workflow", workflow_id);
        const response = await fetch(`${ENDPOINTS.workflows}/${workflow_id}`, {
          method: "DELETE",
          headers: jsonAuthHeaders(userToken),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error(res.message || res.error || "");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflows]);
        queryClient.invalidateQueries([ENDPOINTS.workflow_versions]);
      },
      onError: (error) => {
        console.log("Error deleting workflow", error);
      },
    },
    queryClient,
  );

export const createPlayFromHereMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    {
      success: boolean;
      message?: string;
      error?: string;
    },
    unknown,
    { workflow_node_id: string }
  >(
    {
      mutationFn: async ({ workflow_node_id }) => {
        const response = await fetch(`${ENDPOINTS.workflow_play_from_here}`, {
          method: "POST",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({ workflow_node_id }),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error(res.message || res.error || "");
        }
      },
      onError: (error) => {
        console.log("Error playing workflow from node", error);
      },
    },
    queryClient,
  );
export const createAddWorkflowLabelMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    {
      success: boolean;
      workflow_label: {
        created_at: string;
        id: number;
        name: string;
        workflow_id: number;
        workflow_version_id: number;
      };
    },
    unknown,
    { name: string; workflow_id: number; workflow_version_id: number }
  >(
    {
      mutationFn: async (variables) => {
        const response = await fetch(`${ENDPOINTS.workflow_labels}`, {
          method: "POST",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify(variables),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error adding workflow label");
        }
        console.log("Successfully added workflow label");
        queryClient.invalidateQueries([ENDPOINTS.workflow_labels]);
      },
      onError: (error) => {
        console.log("Error adding workflow label", error);
      },
    },
    queryClient,
  );

export const createUpdateWorkflowLabelMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    {
      success: boolean;
      workflow_label: {
        created_at: string;
        id: number;
        name: string;
        workflow_id: number;
        workflow_version_id: number;
      };
    },
    unknown,
    { id: number; workflow_version_id: number }
  >(
    {
      mutationFn: async ({ id, workflow_version_id }) => {
        const response = await fetch(`${ENDPOINTS.workflow_labels}/${id}`, {
          method: "PATCH",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({ workflow_version_id }),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error updating workflow label");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflow_labels]);
      },
      onError: (error) => {
        console.log("Error updating workflow label", error);
      },
    },
    queryClient,
  );

export const createDeleteWorkflowLabelMutation = (
  userToken: string,
  queryClient: QueryClient,
) =>
  new MobxMutation<
    {
      success: boolean;
      message: string;
    },
    unknown,
    { id: number }
  >(
    {
      mutationFn: async ({ id }) => {
        const response = await fetch(`${ENDPOINTS.workflow_labels}/${id}`, {
          method: "DELETE",
          headers: jsonAuthHeaders(userToken),
        });
        return handleResponse(response);
      },
      onSuccess: (res) => {
        if (!res.success) {
          console.error("Error deleting workflow label");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflow_labels]);
      },
      onError: (error) => {
        console.log("Error deleting workflow label", error);
      },
    },
    queryClient,
  );
