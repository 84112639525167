import React from "react";

const BandaidContext = React.createContext<any>(false);

const BandaidProvider = ({ children }: { children: React.ReactNode }) => (
  <BandaidContext.Provider value={true}>{children}</BandaidContext.Provider>
);

const useBandaid = () => React.useContext(BandaidContext);

export { BandaidProvider, useBandaid };
