import UserChannelProvider from "@/components/UserChannelProvider";
import CanvasContent from "./CanvasContent";
import Controls from "./Controls";
import ErrorBanner from "./ErrorBanner";
import { FlowConnectionMirrorProvider } from "./hooks/useFlowConnectionMirror";

interface CanvasProps {
  readonly?: boolean;
}

const Canvas = ({ readonly }: CanvasProps) => {
  return (
    <div className="relative h-full w-full">
      <div className="relative h-full w-full overflow-hidden">
        <ErrorBanner />
        <Controls readonly={!!readonly} />
        <UserChannelProvider>
          <FlowConnectionMirrorProvider>
            <CanvasContent readonly={readonly} />
          </FlowConnectionMirrorProvider>
        </UserChannelProvider>
      </div>
    </div>
  );
};

export default Canvas;
