import { NodeOutput } from "@/components/Workflows/types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { CalloutWithTitle } from "@/components/ui/callout";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNode } from "../../../node-context";

const renderHumanReadableOutput = (output: NodeOutput) => {
  const out = () => {
    if (output.display_value.type === "string") {
      return (
        <p className="max-w-[300px] text-sm">{output.display_value.value}</p>
      );
    } else if (output.display_value.type === "object") {
      return (
        <pre className="max-w-[300px] whitespace-pre-wrap break-words font-mono text-sm">
          <span className="font-semibold">[JSON]</span>{" "}
          {JSON.stringify(output.display_value.value, null, 2)}
        </pre>
      );
    } else {
      return (
        <p className="max-w-[300px] text-sm">
          {String(output.display_value.value)}
        </p>
      );
    }
  };
  return (
    <CalloutWithTitle
      title={output.display_value.type.toUpperCase()}
      titleSize="text-sm"
    >
      {out()}
    </CalloutWithTitle>
  );
};

const NodeOutputs = () => {
  const node = useNode();
  const outputs: NodeOutput[] = node?.outputs || [];
  const [isOpen, setIsOpen] = useState(false);

  const sortedOutputs = [...outputs].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );

  return (
    outputs.length > 0 && (
      <div className="-mb-3 w-full border-t border-gray-200 bg-gray-200 bg-opacity-20">
        <Accordion
          type="single"
          collapsible
          className="w-full"
          onValueChange={(value) => setIsOpen(value === "output")}
        >
          <AccordionItem value="output">
            <AccordionTrigger
              className="rounded-b-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 hover:bg-opacity-20 hover:text-gray-900"
              onClick={(e) => e.stopPropagation()}
            >
              Node Output ({outputs.length})
            </AccordionTrigger>
            <AccordionContent
              className=" mx-auto w-full  rounded-b-md border-t border-gray-100 px-4 py-3"
              style={{ pointerEvents: isOpen ? "auto" : "none" }}
            >
              <div className="nowheel max-h-[200px] space-y-4 overflow-y-auto">
                {sortedOutputs.map((output, index) => (
                  <div key={index}>
                    <h4 className="mb-2 text-xs font-semibold text-gray-500">
                      Run #{sortedOutputs.length - index} -{" "}
                      {new Date(output.date).toLocaleString()}
                    </h4>
                    <div className="rounded bg-white px-4 py-2 shadow-sm">
                      {renderHumanReadableOutput(output)}
                    </div>
                  </div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    )
  );
};

export default observer(NodeOutputs);
