import { Breadcrumbs } from "@/components/Breadcrumbs";
import DropdownMenu from "@/components/DropdownMenu";
import SelectPromptRegistryObjectsModal from "@/components/SelectPromptRegistryObjectsModal";
import SelectReleaseLabelModal from "@/components/SelectReleaseLabelModal";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import useHasWorkflowAccess from "@/hooks/useHasWorkflowAccess";
import {
  useDeleteReleaseLabelGroup,
  useReleaseLabelGroups,
  useWorkflowReleaseLabelGroups,
} from "@/queries";
import { displayToast } from "@/utils/toast";
import { AdjustmentsIcon, SearchIcon } from "@heroicons/react/outline";
import { History } from "lucide-react";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import RegistryTabs from "../Registry/RegistryTabs";

const ReleaseLabelGroups = () => {
  const [
    isSelectPromptRegistryObjectsModalOpen,
    setSelectPromptRegistryObjectsModalOpen,
  ] = useState(false);
  const [isSelectReleaseLabelModalOpen, setSelectReleaseLabelModalOpen] =
    useState(false);
  const [selectedLabelName, setSelectedLabelName] = useState<string | null>(
    null,
  );
  const [selectedLabelType, setSelectedLabelType] = useState<
    "prompt" | "workflow"
  >("prompt");
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const userContext = useUser();
  const deleteReleaseLabelGroup = useDeleteReleaseLabelGroup(
    authContext!.userToken!,
  );
  const { data, isLoading } = useReleaseLabelGroups(
    userToken!,
    userContext.activeWorkspaceId!,
  );

  const { data: workflowLabelGroups, isLoading: isLoadingWorkflowLabelGroups } =
    useWorkflowReleaseLabelGroups(userToken!, userContext.activeWorkspaceId!);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (selectedLabelName) setSelectPromptRegistryObjectsModalOpen(true);
  }, [selectedLabelName]);

  const releaseLabelGroupList = useMemo(() => {
    if (!data || !data.release_label_groups) return [];
    const promptGroups = data.release_label_groups.filter((group) =>
      group.prompt_label_name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    const workflowGroups =
      workflowLabelGroups?.workflow_release_label_groups?.filter((group) =>
        group.workflow_label_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      ) || [];
    return [...promptGroups, ...workflowGroups];
  }, [data, workflowLabelGroups, searchQuery]);

  const handleDeleteClick = async (
    releaseLabelGroupId: number,
    type: "prompt" | "workflow",
  ) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this experiment?",
    );
    if (isConfirmed) {
      try {
        await deleteReleaseLabelGroup.mutateAsync(releaseLabelGroupId);
        displayToast("A/B release deleted", ToastType.success);
      } catch (error: unknown) {
        console.log(error);
      }
    }
  };

  const handleSelectPromptRegistryObjectsModalClose = () => {
    setSelectPromptRegistryObjectsModalOpen(false);
    setSelectedLabelName(null);
  };

  const handleSelectReleaseLabelModalSubmit = (
    promptLabelName: string,
    type: "prompt" | "workflow",
  ) => {
    setSelectedLabelType(type);
    setSelectedLabelName(promptLabelName);
  };

  const renderContent = () => {
    if (isLoading || isLoadingWorkflowLabelGroups) {
      return (
        <div className="mt-12 flex h-full flex-col items-center justify-center">
          <LoadingSpinner size={10} />
        </div>
      );
    }
    if (releaseLabelGroupList.length === 0) {
      return (
        <div className="mt-12 flex h-full flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-y-4 rounded-md border border-gray-100 bg-gray-50 p-8 text-gray-500">
            <span className="text-xl text-gray-500">
              No A/B releases found.
            </span>
            <span className="max-w-[30vw] text-center text-sm text-gray-400">
              Dynamic release labels can be used to retrieve different versions
              of a prompt or workflow based on a probability distribution or
              user metadata segmentation.
            </span>
          </div>
        </div>
      );
    }

    return (
      <ul className="grid grid-cols-1 gap-6 pt-4 sm:grid-cols-2 lg:grid-cols-3">
        {renderReleaseLabelGroups()}
      </ul>
    );
  };

  const hasWorkflowAccess = useHasWorkflowAccess();

  const renderReleaseLabelGroups = () => {
    return releaseLabelGroupList.map((releaseLabelGroup) => {
      const isWorkflow = "workflow_label_name" in releaseLabelGroup;

      const link = `/workspace/${userContext.activeWorkspaceId!}/ab-releases/${
        isWorkflow ? "workflow-release-group" : "prompt-release-group"
      }/${releaseLabelGroup.id}`;
      const labelName = isWorkflow
        ? releaseLabelGroup.workflow_label_name
        : releaseLabelGroup.prompt_label_name;
      return (
        <li
          className="group col-span-1 flex flex-col rounded-md border-2 border-gray-100 bg-white px-4 py-3 shadow-sm hover:border-blue-100"
          key={`release-label-group-${releaseLabelGroup.id}`}
        >
          <div className="w-full">
            <div className="flex items-center justify-between">
              <div className="flex-1 overflow-hidden">
                <Link to={link}>
                  <h3 className="text-md title cursor-pointer break-all font-medium group-hover:text-blue-500">
                    <AdjustmentsIcon className="mr-2 inline h-6 w-auto rounded-full border border-dashed border-gray-300 p-1 text-gray-600" />
                    {labelName}
                  </h3>
                </Link>
              </div>
              <DropdownMenu
                iconSize="5"
                menuItems={[
                  {
                    text: "Delete",
                    onClick: () =>
                      handleDeleteClick(
                        releaseLabelGroup.id,
                        isWorkflow ? "workflow" : "prompt",
                      ),
                  },
                ]}
              />
            </div>
          </div>
          <Link to={link} className="flex h-full flex-col justify-end">
            <div className="mt-auto flex justify-between pt-1">
              <span
                className={`inline-flex ${
                  !hasWorkflowAccess ? "hidden" : ""
                } items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                  isWorkflow
                    ? "bg-purple-100 text-purple-800"
                    : "bg-green-100 text-green-800"
                }`}
              >
                {isWorkflow ? "Workflow" : "Prompt"}
              </span>
              <div className="ml-auto flex flex-shrink-0 cursor-pointer items-center gap-x-1 text-xs font-normal text-gray-400">
                <History className="ml-3 inline-block h-4 w-4" />
                {moment(releaseLabelGroup.created_at).fromNow()}
              </div>
            </div>
          </Link>
        </li>
      );
    });
  };

  const renderSearchBar = () => {
    return (
      <div className="border-b py-2">
        <div className="flex items-center">
          <div className="mt-3 flex flex-1 items-center rounded-sm px-1">
            <SearchIcon
              className="mr-2 h-5 w-auto text-gray-400"
              aria-hidden="true"
            />
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              type="text"
              name="search"
              id="search"
              className="block w-full rounded-sm border-0 border-transparent focus:border-transparent focus:ring-0 sm:text-sm"
              placeholder="Search release labels..."
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="p-1">
        <Breadcrumbs
          items={["Registry", "A/B Releases"]}
          navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/prompt`}
          tabsAboveTitle={<RegistryTabs />}
          pageTitle="A/B Releases"
          pageSubtitle={
            <>
              Build A/B tests, segment user groups, and incrementally release
              new prompt or workflow versions.{" "}
              <a
                href="https://docs.promptlayer.com/why-promptlayer/ab-releases"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 hover:text-blue-600"
              >
                Learn more.
              </a>
            </>
          }
          rightTitleContent={
            <>
              <Button onClick={() => setSelectReleaseLabelModalOpen(true)}>
                Create A/B Release
              </Button>
            </>
          }
        />
        {renderSearchBar()}
        <div className="mt-4 flex-1">{renderContent()}</div>
      </div>
      <SelectReleaseLabelModal
        isOpen={isSelectReleaseLabelModalOpen}
        onSubmit={handleSelectReleaseLabelModalSubmit}
        setOpen={setSelectReleaseLabelModalOpen}
        currentExperimentsCount={releaseLabelGroupList.length}
      />
      {selectedLabelName && (
        <SelectPromptRegistryObjectsModal
          isOpen={isSelectPromptRegistryObjectsModalOpen}
          selectedLabelName={selectedLabelName}
          selectedLabelType={selectedLabelType}
          setOpen={handleSelectPromptRegistryObjectsModalClose}
        />
      )}
    </>
  );
};

export default ReleaseLabelGroups;
