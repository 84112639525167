import React, { memo, useCallback, useState } from "react";

import { EyeIcon } from "@heroicons/react/outline";
import { InlineEdit } from ".";
import LoadingSpinner from "./LoadingSpinner";
import TagsEditor from "./TagsEditor";
import { Button } from "./ui/button";

interface ResourceHeaderProps {
  title: string;
  onTitleEdit: (newTitle: string) => void;
  id: string | number;
  createdAt: string;
  versionInfo?: {
    current: number;
    total: number;
  };
  format?: string;
  tags?: string[];
  onTagsEdit?: (newTags: string[]) => void;
  actions: Array<
    | {
        label: string;
        icon?: React.ReactNode;
        onClick: () => void;
        variant?:
          | "default"
          | "destructive"
          | "outline"
          | "secondary"
          | "ghost"
          | "link"
          | "destructiveOutline"
          | "secondaryOutline";
      }
    | React.ReactNode
  >;
  showVersions?: boolean;
  onToggleVersions?: () => void;
  isLoading?: boolean;
}

const Title: React.FC<{
  title: string;
  onTitleEdit: (newTitle: string) => void;
}> = ({ title, onTitleEdit }) => (
  <InlineEdit.Root initialValue={title} onEdit={onTitleEdit} className="p-0">
    <InlineEdit.Trigger>
      {title === "Loading..." ? (
        <div className="h-8 w-64 animate-pulse rounded bg-gray-50"></div>
      ) : (
        <h1
          className={`cursor-text ${
            title.length > 30 ? "text-2xl" : "text-3xl"
          } break-words font-semibold`}
        >
          {title}
        </h1>
      )}
    </InlineEdit.Trigger>
    <InlineEdit.Input className="py-1 text-base" />
    <InlineEdit.Rename>Rename</InlineEdit.Rename>
    <InlineEdit.Cancel>Cancel</InlineEdit.Cancel>
  </InlineEdit.Root>
);

const MetaInfo: React.FC<{
  createdAt: string;
  id: string | number;
  versionInfo?: { current: number; total: number };
  format?: string;
  showVersions?: boolean;
  onToggleVersions?: () => void;
  isLoading?: boolean;
}> = memo(
  ({
    createdAt,
    id,
    versionInfo,
    format,
    showVersions,
    onToggleVersions,
    isLoading,
  }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = useCallback(() => {
      navigator.clipboard.writeText(id.toString());
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    }, [id]);

    const renderVersionInfo = useCallback(() => {
      if (!versionInfo) return null;

      if (!versionInfo.total)
        return (
          <div className="h-4 w-20 animate-pulse rounded bg-gray-50"></div>
        );
      return (
        <span className="text-sm text-gray-600">
          Version {versionInfo.current} of {versionInfo.total}
        </span>
      );
    }, [versionInfo]);

    const renderFormat = useCallback(() => {
      if (!format) return null;
      return (
        <>
          <div className="h-1 w-1 rounded-full bg-gray-300"></div>
          <div className="text-sm text-gray-600">{format}</div>
        </>
      );
    }, [format]);

    const renderToggleVersions = useCallback(() => {
      if (showVersions || !onToggleVersions || versionInfo?.total! <= 1)
        return null;
      return (
        <>
          <div className="h-1 w-1 rounded-full bg-gray-300"></div>
          <div onClick={onToggleVersions}>
            <Button onClick={onToggleVersions} variant="link" size="sm">
              <EyeIcon className="mr-1 inline h-4 w-auto" />
              Show Previous Versions
            </Button>
          </div>
        </>
      );
    }, [showVersions, onToggleVersions, versionInfo?.total]);

    return (
      <div className="flex items-center space-x-2">
        {createdAt && (
          <>
            <h4 className="text-sm text-gray-600" title={createdAt}>
              {versionInfo && versionInfo.total > 1
                ? "Last Updated: "
                : "Created: "}
              {createdAt}
            </h4>
            <div className="h-1 w-1 rounded-full bg-gray-300"></div>
          </>
        )}

        {id !== -1 && (
          <>
            <span
              className="group cursor-pointer text-sm text-gray-600"
              onClick={handleCopy}
            >
              <span className={!copied ? "group-hover:hidden" : ""}>
                {copied ? "Copied to clipboard ✅" : `ID: ${id}`}
              </span>
              <span className={`hidden ${copied ? "" : "group-hover:block"}`}>
                Click to Copy...
              </span>
            </span>
            <div className="h-1 w-1 rounded-full bg-gray-300"></div>
          </>
        )}
        {renderVersionInfo()}
        {renderFormat()}
        {renderToggleVersions()}
        {isLoading && <LoadingSpinner size={4} />}
      </div>
    );
  },
);

const Actions: React.FC<{ actions: ResourceHeaderProps["actions"] }> = memo(
  ({ actions }) => (
    <div className="flex gap-x-2.5">
      {actions.map((action, index) =>
        React.isValidElement(action) ? (
          React.cloneElement(action, { key: `action-${index}` })
        ) : (
          <Button
            key={`action-${(action as { label: string }).label}-${index}`}
            onClick={(action as { onClick: () => void }).onClick}
            variant={
              (action as { variant?: string }).variant || ("outline" as any)
            }
            size="sm"
            className="inline-flex items-center"
          >
            {(action as { icon?: React.ReactNode }).icon}
            {(action as { label: string }).label}
          </Button>
        ),
      )}
    </div>
  ),
);

export const ResourceHeader: React.FC<ResourceHeaderProps> = ({
  title,
  onTitleEdit,
  id,
  createdAt,
  versionInfo,
  format,
  tags,
  onTagsEdit,
  actions,
  showVersions,
  onToggleVersions,
  isLoading = false,
}) => {
  return (
    <div className="flex">
      <div className="flex-1">
        <div className="flex items-center space-x-1">
          <Title title={title} onTitleEdit={onTitleEdit} />
          {tags && onTagsEdit && (
            <div className="-mt-1 px-2">
              <TagsEditor tags={tags} onEdit={onTagsEdit} maxTags={10} />
            </div>
          )}
        </div>
        <div className="flex items-center justify-between pb-3 xl:pb-8">
          <MetaInfo
            createdAt={createdAt}
            id={id}
            versionInfo={versionInfo}
            format={format}
            showVersions={showVersions}
            onToggleVersions={onToggleVersions}
            isLoading={isLoading}
          />
          <Actions actions={actions} />
        </div>
      </div>
    </div>
  );
};
