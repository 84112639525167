import { PlusIcon } from "@heroicons/react/solid";
import { Node, useReactFlow } from "@xyflow/react";
import { useCallback } from "react";
import { v4 } from "uuid";
import { useWorkflow } from "../../workflow-context";

interface BackgroundContextMenuProps {
  onClose?: () => void;
  position: { x: number; y: number };
}

const BackgroundContextMenu = ({
  onClose,
  position,
}: BackgroundContextMenuProps) => {
  const { setNodes, setCenter } = useReactFlow();
  const workflow = useWorkflow();

  const handleAddNode = useCallback(() => {
    const uuid = v4();
    const newNode = {
      id: uuid,
      position,
      data: { label: workflow.nextNodeLabel, type: "pending" },
      type: "PENDING",
    };

    setNodes((nds: Node[]) => nds.concat(newNode));
    setCenter(newNode.position.x + 350, newNode.position.y, {
      duration: 750,
      zoom: 1,
    });
    onClose && onClose();
  }, [position, workflow.nextNodeLabel, setNodes, setCenter, onClose]);

  return (
    <button
      className="inline-flex w-full  items-center px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
      onClick={handleAddNode}
    >
      <PlusIcon className="mr-2 h-4 w-auto text-gray-700" />
      Add new node
    </button>
  );
};

export default BackgroundContextMenu;
