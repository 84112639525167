import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useAuth } from "@/context/auth-context";
import { useWorkflowVersionExecutions } from "@/queries";
import { useState } from "react";
import { useParams } from "react-router-dom";

import LoadingSpinner from "@/components/LoadingSpinner";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface WorkflowExecutionTableProps {
  workflowVersionId: number;
}

const WorkflowExecutionTable = ({
  workflowVersionId,
}: WorkflowExecutionTableProps) => {
  const authContext = useAuth();
  const userToken = authContext?.userToken || "";
  const { workspaceId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    data,
    isLoading,
    isFetching,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useWorkflowVersionExecutions(
    workflowVersionId,
    Number(workspaceId),
    userToken,
    currentPage,
    rowsPerPage,
  );

  const pending = isLoading || isFetching;

  const items = data?.pages[0]?.workflow_version_executions || [];

  const headers = items.length > 0 ? Object.keys(items[0]) : [];

  const totalPages = data?.pages[0]?.pages || 1;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (page > currentPage && hasNextPage) {
      fetchNextPage();
    }
  };

  const handleRowsPerPageChange = (value: string) => {
    setRowsPerPage(Number(value));
    setCurrentPage(1);
  };

  if (error) {
    return <div className="text-red-500">Error: {error.message}</div>;
  }

  return (
    <div className="space-y-4 pb-6">
      <div className="relative min-h-[400px]">
        {isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  {headers.map((header) => (
                    <TableHead key={header}>{header}</TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id}>
                    {Object.values(item).map((value, index) => (
                      <TableCell
                        key={`${item.id}-${index}`}
                        className="max-w-xs overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {typeof value === "string"
                          ? value
                          : JSON.stringify(value, null, 2)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        {pending && !isLoading && (
          <div className="absolute right-0 top-0 m-4">
            <LoadingSpinner size="small" />
          </div>
        )}
      </div>
      <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
        <div className="flex items-center space-x-2">
          <span>Rows per page:</span>
          <Select
            value={rowsPerPage.toString()}
            onValueChange={handleRowsPerPageChange}
          >
            <SelectTrigger className="w-[80px]">
              <SelectValue>{rowsPerPage}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="10">10</SelectItem>
              <SelectItem value="25">25</SelectItem>
              <SelectItem value="50">50</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            variant="outline"
            size="sm"
          >
            Previous
          </Button>
          <span className="text-sm">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages && !hasNextPage}
            variant="outline"
            size="sm"
          >
            Next
          </Button>
        </div>
      </div>
      {isFetchingNextPage && (
        <div className="flex justify-center">
          <LoadingSpinner size="small" />
        </div>
      )}
    </div>
  );
};

export default WorkflowExecutionTable;
