import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/outline";
import { useState } from "react";

export const CopyButton: React.FC<{
  text: string;
  iconSize?: string;
  className?: string;
}> = ({ text, className, iconSize = "h-5 w-5" }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyText = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <button
      onClick={copyText}
      className={`text-gray-600 hover:text-gray-800 focus:outline-none ${className}`}
    >
      {isCopied ? (
        <CheckIcon className={iconSize} />
      ) : (
        <DocumentDuplicateIcon className={iconSize} />
      )}
    </button>
  );
};
