import { DragHandleDots2Icon, MagicWandIcon } from "@radix-ui/react-icons";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import LoadingSpinner from "@/components/LoadingSpinner";
import WorkflowCanvas from "@/components/Workflows/WorkflowCanvas";
import useWorkflowData from "@/components/Workflows/WorkflowVersion/hooks/useWorkflowData";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useTraceSpans } from "@/queries";
import { SpanNode } from "@/types/spans";
import { getDuration } from "@/utils/duration";
import { truncate } from "@/utils/strings";
import { CodeIcon } from "@heroicons/react/outline";
import { DivButton } from "../ui/button";
import SpanDetails from "./SpanDetails";

const MIN_SPAN_TREE_WIDTH = 200;
const MAX_SPAN_TREE_WIDTH = 600;
const DEFAULT_SPAN_TREE_WIDTH = 400;

const TraceDetail: FC = () => {
  const [selectedSpan, setSelectedSpan] = useState<SpanNode | null>(null);
  const [spanTreeWidth, setSpanTreeWidth] = useState(DEFAULT_SPAN_TREE_WIDTH);
  const [isDragging, setIsDragging] = useState(false);
  const dividerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { traceId } = useParams<{ traceId: string }>();
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext!.userToken!;

  const navigate = useNavigate();
  const { workflow } = useWorkflowData(
    {
      workflowId: selectedSpan?.attributes?.workflow_id,
      versionNumber: selectedSpan?.attributes?.workflow_version,
      userToken: userToken,
      workspaceId: selectedSpan?.attributes?.workspace_id,
    },
    false,
  );

  // Check if workflow available in Workflow DAG to show canvas
  const isWorkflow =
    selectedSpan?.attributes?.workflow_id &&
    selectedSpan?.attributes?.workflow_version &&
    workflow;

  useEffect(() => {
    setSelectedSpan(null);
  }, [traceId]);

  const { data, isLoading, error } = useTraceSpans({
    userToken,
    traceId: traceId!,
    workspace_id: userContext.activeWorkspaceId!,
  });

  const spanTree = useMemo(() => {
    if (!data?.spans) return null;

    const spanMap = new Map<string, SpanNode>();
    const rootNodes: SpanNode[] = [];

    data.spans.forEach((span) => {
      spanMap.set(span.span_id, { ...span, children: [] });
    });

    spanMap.forEach((span) => {
      if (span.parent_id === null) {
        rootNodes.push(span);
      } else {
        const parent = spanMap.get(span.parent_id);
        if (parent) {
          parent.children.push(span);
        }
      }
    });

    const sortSpans = (nodes: SpanNode[]): SpanNode[] => {
      nodes.sort(
        (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime(),
      );
      nodes.forEach((node) => {
        node.children = sortSpans(node.children);
      });
      return nodes;
    };

    return sortSpans(rootNodes);
  }, [data?.spans]);

  useEffect(() => {
    if (spanTree && spanTree.length > 0 && !selectedSpan) {
      setSelectedSpan(spanTree[0]);
    }
  }, [spanTree, selectedSpan]);

  const handleSpanClick = (span: SpanNode) => {
    setSelectedSpan(span);
  };

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !containerRef.current) return;
      const containerRect = containerRef.current.getBoundingClientRect();
      const newWidth = e.clientX - containerRect.left;
      setSpanTreeWidth((prevWidth) => {
        const clampedWidth = Math.max(
          MIN_SPAN_TREE_WIDTH,
          Math.min(MAX_SPAN_TREE_WIDTH, newWidth),
        );
        return clampedWidth;
      });
    },
    [isDragging],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const renderHeader = () => {
    return (
      <div className="flex pt-4">
        <div className="flex-1 pt-4">
          <div className="flex flex-col sm:flex-row sm:justify-between">
            <h1 className="text-2xl font-semibold">Trace Spans</h1>
            <span className="mt-2 text-sm font-normal text-gray-400 sm:mt-0">
              Trace ID: {traceId}
            </span>
          </div>
          <h2 className="pt-1 text-lg text-gray-500">
            View and analyze trace spans
          </h2>
        </div>
      </div>
    );
  };

  const renderIcon = (spanNode: SpanNode) => {
    let bgColor = "bg-purple-400";
    let icon = <MagicWandIcon className="h-3.5 w-3.5 text-white" />;

    if (!spanNode.request_log_id) {
      bgColor = "bg-blue-400";
      icon = <CodeIcon className="h-3.5 w-3.5 text-white" />;
    }

    return (
      <div
        className={`mr-2 inline-flex items-center justify-center rounded p-1 shadow-sm ${bgColor}`}
      >
        {icon}
      </div>
    );
  };

  const renderSpanTree = (spanNodes: SpanNode[], depth = 0) => {
    return (
      <ul className={depth > 0 ? "relative ml-4" : ""}>
        {spanNodes.map((spanNode, index) => {
          const isRequest = spanNode.request_log_id;
          const isLastChild = index === spanNodes.length - 1;
          return (
            <li key={spanNode.span_id} className="relative">
              {depth > 0 && (
                <div
                  className="absolute left-0 top-0 w-[1px] bg-gray-300"
                  style={{
                    left: "-1rem",
                    height: isLastChild ? "50%" : "100%",
                  }}
                />
              )}
              <div className="relative py-1">
                {depth > 0 && (
                  <div
                    className="absolute h-[1px] w-3 bg-gray-300"
                    style={{
                      left: "-1rem",
                      top: "50%",
                    }}
                  />
                )}
                <div
                  className={`flex cursor-pointer items-center rounded px-1 py-0.5 text-sm hover:bg-gray-100 ${
                    selectedSpan?.span_id === spanNode.span_id
                      ? "bg-blue-100"
                      : ""
                  }`}
                  onClick={() => handleSpanClick(spanNode)}
                >
                  {renderIcon(spanNode)}
                  <span className="truncate text-xs font-medium">
                    {truncate(spanNode.name, 30)}
                  </span>
                  <span className="ml-1 hidden text-xs text-gray-400 sm:inline">
                    {getDuration(spanNode.start, spanNode.end)}
                  </span>
                  {isRequest && (
                    <DivButton
                      className={"ml-auto h-1 whitespace-nowrap text-xs"}
                      size={"sm"}
                      variant={"link"}
                      onClick={() => {
                        navigate(
                          `/workspace/${userContext?.activeWorkspaceId}/request/${spanNode.request_log_id}`,
                        );
                      }}
                    >
                      Open
                    </DivButton>
                  )}
                </div>
              </div>
              {spanNode.children.length > 0 &&
                renderSpanTree(spanNode.children, depth + 1)}
            </li>
          );
        })}
      </ul>
    );
  };

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner size={12} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">Error: {error.message}</div>;
  }

  return (
    <div
      className={`flex h-full flex-col overflow-hidden ${
        isLoading ? "animate-pulse" : ""
      }`}
    >
      <div className="flex-shrink-0 px-4 pt-4">{renderHeader()}</div>
      <div className="flex flex-grow overflow-hidden pb-4  pt-4">
        {!spanTree || spanTree.length === 0 ? (
          <p className="p-4 text-gray-500">No spans found for this trace.</p>
        ) : (
          <div
            ref={containerRef}
            className="flex w-full flex-col overflow-hidden rounded-lg bg-white shadow-sm lg:flex-row"
          >
            <div
              className="w-full flex-shrink-0 overflow-auto border-b p-4 lg:w-auto lg:border-b-0 "
              style={{
                [window.innerWidth > 1024
                  ? "width"
                  : "maxHeight"]: `${spanTreeWidth}px`,
                minWidth: `${MIN_SPAN_TREE_WIDTH}px`,
                maxWidth: `${MAX_SPAN_TREE_WIDTH}px`,
              }}
            >
              {renderSpanTree(spanTree)}
            </div>
            <div
              ref={dividerRef}
              onMouseDown={handleMouseDown}
              className="relative z-0 hidden w-4 flex-shrink-0 cursor-col-resize before:absolute before:bottom-0 before:top-0 before:w-[1px] before:bg-gray-200 before:content-[''] lg:flex lg:items-center lg:justify-center"
            >
              <div className="z-[1] flex h-16 w-4  items-center justify-center rounded-full bg-gray-300">
                <DragHandleDots2Icon className="h-4 w-4 text-gray-500" />
              </div>
            </div>
            <div className="w-full overflow-auto p-4 lg:flex-grow">
              {isWorkflow && (
                <div
                  className={`mb-5 flex h-60 w-full flex-grow transition-opacity duration-200 ease-in-out ${
                    isLoading && "opacity-25"
                  }`}
                >
                  <WorkflowCanvas
                    readonly={true}
                    {...workflow}
                    userToken={userToken}
                  />
                </div>
              )}
              <SpanDetails selectedSpan={selectedSpan} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TraceDetail;
