import LoadingSpinner from "@/components/LoadingSpinner";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  useGetReportColumnStats,
  usePromptRegistryObjects,
  usePromptVersion,
  usePromptVersions,
} from "@/queries";
import { PromptTemplateConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { ExternalLinkIcon, PencilIcon } from "@heroicons/react/outline";
import { useCallback, useMemo, useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";
import StatisticGraphs from "./StatisticGraphs";

interface ModalPageTabsProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  navItem1: string;
  navItem2: string;
}

const ModalPageTabs: React.FC<ModalPageTabsProps> = ({
  activeTab,
  setActiveTab,
  navItem1,
  navItem2,
}) => {
  return (
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        <button
          onClick={() => setActiveTab(navItem1)}
          className={`inline-flex items-center border-b-2 border-transparent pb-3 text-sm font-medium ${
            activeTab === navItem1
              ? "border-gray-400 text-gray-800"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
          aria-current={activeTab === navItem1 ? "page" : undefined}
        >
          {navItem1}
        </button>
        <button
          onClick={() => setActiveTab(navItem2)}
          className={`inline-flex items-center border-b-2 border-transparent pb-3 text-sm font-medium ${
            activeTab === navItem2
              ? "border-gray-400 text-gray-800"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
          aria-current={activeTab === navItem2 ? "page" : undefined}
        >
          {navItem2}
        </button>
      </nav>
    </div>
  );
};

const HomeStep = ({
  navigateAway,
  navigateNext,
  setCurrentNavigation,
  availableColumns,
  configuration,
  setConfiguration,
  columnName,
  setColumnName,
  editable,
  columnId,
}: {
  navigateAway: () => void;
  navigateNext: () => void;
  setCurrentNavigation: (navigation: string) => void;
  availableColumns: ReportColumn[];
  configuration: Partial<PromptTemplateConfiguration>;
  setConfiguration: (
    data:
      | Partial<PromptTemplateConfiguration>
      | ((
          prevState: Partial<PromptTemplateConfiguration>,
        ) => Partial<PromptTemplateConfiguration>),
  ) => void;
  columnName: string;
  setColumnName: (name: string) => void;
  editable: boolean;
  columnId?: number;
}) => {
  const authContext = useAuth();
  const userContext = useUser();
  const bandaid = useBandaid();
  const workspaceId = userContext?.activeWorkspaceId!;
  const userToken = authContext?.userToken!;

  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const templates =
    promptRegistryObjects.data?.pages.flatMap((page) => page.items) || [];

  const selectedPromptRegistry = templates.find(
    (template) => template.prompt_name === configuration.template?.name,
  );

  const promptVersionQuery = usePromptVersions(userToken, {
    workspaceId,
    promptRegistryId: selectedPromptRegistry?.id,
    perPage: Number.MAX_SAFE_INTEGER,
  });
  const promptVersions =
    promptVersionQuery.data?.pages.flatMap((page) => page.items) || [];

  const version =
    promptVersions.find(
      (version) => version?.number === configuration.template?.version_number,
    ) || promptVersions.at(0);

  const promptTemplateVersionQuery = usePromptVersion(
    userToken,
    // @ts-ignore
    selectedPromptRegistry?.id,
    version?.number,
  );

  const promptTemplateVersion = promptTemplateVersionQuery.data;

  const inputVariables =
    promptTemplateVersion?.rendered_template?.input_variables || [];

  const onUpdateInputVariableMapping = useCallback(
    (inputVariable: string, columnName: string) => {
      const newInputVariableMapping = {
        ...(configuration.prompt_template_variable_mappings || {}),
        [inputVariable]: columnName,
      };
      setConfiguration({
        ...configuration,
        prompt_template_variable_mappings: newInputVariableMapping,
      });
    },
    [configuration, setConfiguration],
  );

  const fillInputVariables = () => {
    const newMappings = {
      ...(configuration.prompt_template_variable_mappings || {}),
    };
    inputVariables.forEach((variable) => {
      const matchingColumn = availableColumns.find(
        (column) =>
          column.name === variable ||
          column.name === `variable.${variable}` ||
          column.name === `metadata.${variable}`,
      );
      if (matchingColumn) {
        newMappings[variable] = matchingColumn.name;
      }
    });
    setConfiguration({
      ...configuration,
      prompt_template_variable_mappings: newMappings,
    });
  };

  const templateVersionLink: string | null = useMemo(() => {
    if (promptTemplateVersion) {
      return `/workspace/${workspaceId}/prompt/${promptTemplateVersion.prompt_id}/version/${promptTemplateVersion.number}`;
    }
    return null;
  }, [promptTemplateVersion, workspaceId]);

  const templateDisplayName: string | null = useMemo(() => {
    if (!configuration.template?.name) {
      return null;
    }
    const versionNumber = configuration.template?.version_number;
    const label = configuration.template?.label;
    if (versionNumber === null) {
      return `${configuration.template.name}[latest]`;
    } else if (versionNumber !== undefined) {
      return `${configuration.template.name}[#${configuration.template?.version_number}]`;
    } else if (label !== undefined) {
      return `${configuration.template.name}[label=${configuration.template?.label}]`;
    } else {
      // Invalid. No number or label
      return null;
    }
  }, [configuration.template]);

  const engineDisplayText: any | null = useMemo(() => {
    if (!templateDisplayName && !configuration.engine) {
      return null;
    } else if (templateDisplayName && !configuration.engine) {
      return <span className="text-gray-400">Default</span>;
    } else {
      return configuration.engine?.model || null;
    }
  }, [templateDisplayName, configuration.engine]);

  const handleSave = () => {
    navigateNext();
  };

  const { data: reportColumnStats, isLoading: isLoadingStats } =
    useGetReportColumnStats(authContext?.userToken || "", columnId);
  const [showStats, setShowStats] = useState(false);

  return (
    <ModalStep
      navigatePrevious={navigateAway}
      navigateNext={handleSave}
      nextButtonText={editable ? "Run Step" : "Done"}
    >
      <div className="grid grid-cols-2 items-center gap-4">
        <h2 className="col-span-2 text-lg font-semibold">
          Configure Prompt Template
        </h2>
        {!editable && (
          <div className="col-span-2">
            <ModalPageTabs
              activeTab={showStats ? "Statistics" : "Configuration"}
              setActiveTab={(tab) => setShowStats(tab === "Statistics")}
              navItem1="Configuration"
              navItem2="Statistics"
            />
          </div>
        )}
        {showStats ? (
          <div className="col-span-2">
            {isLoadingStats ? (
              <LoadingSpinner />
            ) : (
              <StatisticGraphs reportColumnStats={reportColumnStats} />
            )}
          </div>
        ) : (
          <>
            <label
              htmlFor="column-name"
              className=""
              style={{ display: bandaid ? "none" : "" }}
            >
              {bandaid ? "Node name:" : "Column name:"}
            </label>
            <input
              id="column-name"
              className="rounded border border-gray-300 px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50"
              value={columnName || ""}
              onChange={(e) => setColumnName(e.target.value)}
              disabled={!editable}
              style={{ display: bandaid ? "none" : "" }}
            />
            <div>Prompt template:</div>
            {templateDisplayName && templateVersionLink ? (
              <div className="flex items-center space-x-2">
                <span className="flex-1 break-all text-gray-600">
                  {templateDisplayName}{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={templateVersionLink}
                  >
                    <ExternalLinkIcon className="inline-block h-4 w-4 text-gray-600" />
                  </a>
                </span>
                <Button
                  size="tinyIcon"
                  variant="outline"
                  onClick={() => setCurrentNavigation("select-prompt-template")}
                >
                  <PencilIcon className="inline-block h-4 w-4 text-gray-600" />
                </Button>
              </div>
            ) : (
              <Button
                onClick={() => setCurrentNavigation("select-prompt-template")}
              >
                {"Select template"}
              </Button>
            )}
            <div>Model engine:</div>
            {engineDisplayText ? (
              <div className="flex items-center space-x-2">
                <span className="flex-1 break-all text-gray-600">
                  {engineDisplayText}
                </span>
                <div className="flex flex-nowrap">
                  <Button
                    size="tinyIcon"
                    variant="outline"
                    onClick={() =>
                      setCurrentNavigation("select-engine-and-params")
                    }
                    disabled={!configuration.template?.name}
                  >
                    <PencilIcon className="inline-block h-4 w-4 text-gray-600" />
                  </Button>
                </div>
              </div>
            ) : (
              <Button
                onClick={() => setCurrentNavigation("select-engine-and-params")}
                disabled={!configuration.template?.name}
              >
                {"Configure engine"}
              </Button>
            )}
            {templateDisplayName && engineDisplayText && (
              <>
                <div className="col-span-2">
                  Input variables:
                  <p className="my-1 border-l-2 border-gray-300 pl-2 text-sm text-gray-500">
                    Seeing unexpected variables? Try <strong>jinja2</strong>{" "}
                    parsing.{" "}
                    <a
                      href="https://docs.promptlayer.com/features/faq#why-do-i-see-extra-input-variables-in-my-prompt-template-parsing-does-not-seem-to-be-working"
                      className="text-blue-500 hover:text-blue-400"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more
                    </a>
                  </p>
                </div>
                {inputVariables.length === 0 ? (
                  <div className="col-span-2 rounded-md border border-gray-200 bg-gray-50 p-3">
                    <div className="text-sm text-gray-500">
                      {`This template does not have any input variables. Please add {variables} using f-string or jinja.`}
                    </div>
                  </div>
                ) : (
                  <div className="col-span-2 mx-3 rounded-md border border-gray-200 bg-gray-50">
                    <div className="text-right">
                      <Button
                        onClick={fillInputVariables}
                        variant="link"
                        size="sm"
                        className="inline-flex items-center"
                      >
                        Click to auto-fill ✨
                      </Button>
                    </div>
                    <div className="max-h-96 overflow-y-auto px-5 py-2">
                      {(inputVariables || []).map((variable: string) => (
                        <div
                          className="grid grid-cols-3 items-center space-x-2 space-y-2"
                          key={`var-${variable}`}
                        >
                          <div className="col-span-1 mr-2 mt-2 break-all text-right font-mono text-sm text-gray-600">
                            {`{${variable}}`}
                          </div>
                          <div className="col-span-2 w-full">
                            <DropdownMenu>
                              <DropdownMenuTrigger
                                disabled={!editable}
                                className="w-full"
                              >
                                {configuration
                                  .prompt_template_variable_mappings?.[
                                  variable
                                ] || (
                                  <span className="font-normal text-gray-500">
                                    Select a {bandaid ? "node" : "column"}...
                                  </span>
                                )}
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                {availableColumns.map((column) => (
                                  <DropdownMenuItem
                                    key={column.name}
                                    onSelect={() =>
                                      onUpdateInputVariableMapping(
                                        variable,
                                        column.name,
                                      )
                                    }
                                  >
                                    {formatInputVariable(
                                      column.column_type,
                                      column.name,
                                    )}
                                  </DropdownMenuItem>
                                ))}
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </ModalStep>
  );
};

export default HomeStep;
