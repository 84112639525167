import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "../Breadcrumbs";
import RegistryTabs from "./RegistryTabs";
import ShimmerEffect from "./ShimmerEffect";

interface RegistryGridProps<T> {
  title: string;
  subtitle: React.ReactNode;
  createButtonText: string;
  createButtonLink: string;
  itemsKey: string;
  useDataHook: (
    workspaceId: string,
    userToken: string,
  ) => UseInfiniteQueryResult<
    {
      success: boolean;
      [key: string]: any;
    },
    Error
  >;
  renderCard: (
    item: T,
    workspaceId: string,
    onDelete: (item: T) => void,
  ) => React.ReactNode;
  onDeleteItem: (item: T) => void;
}

const RegistryGrid = <T,>({
  title,
  subtitle,
  createButtonText,
  createButtonLink,
  itemsKey,
  useDataHook,
  renderCard,
  onDeleteItem,
}: RegistryGridProps<T>) => {
  const navigate = useNavigate();
  const userContext = useUser();
  const auth = useAuth();
  const { workspaceId } = useParams();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useDataHook(workspaceId ?? "", auth?.userToken ?? "");

  const items = useMemo(
    () => data?.pages.flatMap((page) => page[itemsKey]) ?? [],
    [data, itemsKey],
  );

  const handleCreateClick = useCallback(
    () => navigate(createButtonLink),
    [navigate, createButtonLink],
  );

  const handleLoadMore = useCallback(() => fetchNextPage(), [fetchNextPage]);

  return (
    <div className="p-1">
      <Breadcrumbs
        items={["Registry", `${title}s`]}
        navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/${title.toLowerCase()}`}
        tabsAboveTitle={<RegistryTabs />}
        pageTitle={`${title} Registry`}
        pageSubtitle={subtitle}
        rightTitleContent={
          <Button onClick={handleCreateClick}>{createButtonText}</Button>
        }
      />
      <h2 className="mt-10 text-lg font-medium">{title}s</h2>
      <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {isLoading || !data?.pages ? (
          [...Array(6)].map((_, index) => (
            <li key={`shimmer-${index}`}>
              <ShimmerEffect />
            </li>
          ))
        ) : items.length > 0 ? (
          items.map((item, index) => (
            <React.Fragment key={`item-${index}`}>
              {renderCard(item, workspaceId ?? "", onDeleteItem)}
            </React.Fragment>
          ))
        ) : (
          <li className="col-span-full rounded-md border bg-gray-50 py-8 text-center text-gray-500">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No items found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a new item.
            </p>
          </li>
        )}
      </ul>
      {hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Button onClick={handleLoadMore} disabled={isFetchingNextPage}>
            {isFetchingNextPage ? "Loading more..." : "Load More"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default RegistryGrid;
