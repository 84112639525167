import { classNames } from "@/utils/strings";
import { Tab } from "@headlessui/react";
import { ChatIcon, XIcon } from "@heroicons/react/outline";
import { User } from "lucide-react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

import { WorkflowVersion } from "../../types";
import { IWorkflow } from "../../WorkflowCanvas/workflow-store";

import { useCallback } from "react";

import WorkflowAddReleaseLabelButton from "./WorkflowAddReleaseLabelButton";
interface WorkflowVersionSelectorProps {
  versions: WorkflowVersion[];
  showVersionList: boolean;
  setShowVersionList: (value: boolean) => void;
  workflowRef: React.MutableRefObject<IWorkflow | undefined>;
  refreshVersions: () => void;
}
const WorkflowVersionSelector = ({
  versions,
  showVersionList,
  setShowVersionList,
  workflowRef,
  refreshVersions,
}: WorkflowVersionSelectorProps) => {
  const { workflowId, workspaceId, versionId: versionNumber } = useParams();

  const handleDeleteReleaseLabel = useCallback(
    async (labelId: number, versionId: number) => {
      try {
        await workflowRef.current?.removeReleaseLabel(labelId);
        refreshVersions();
      } catch (error) {
        console.error("Failed to delete release label:", error);
      }
    },
    [refreshVersions, workflowRef],
  );

  return (
    <Tab.Group>
      <div className="relative flex max-w-[25%] flex-col rounded-lg border">
        <div className="flex items-center justify-between rounded-t-lg border-b border-gray-200 bg-gray-50 px-3 py-1">
          <h2 className="mr-12 whitespace-nowrap text-sm font-normal text-gray-600">
            Workflow Versions
          </h2>
          <button
            className="text-xs text-gray-500 hover:text-gray-700"
            onClick={() => setShowVersionList(!showVersionList)}
          >
            Hide
          </button>
        </div>
        <Tab.List className="relative max-h-full overflow-y-scroll">
          {versions.map((version) => {
            const {
              id,
              created_at,
              commit_message,
              number,
              user_email: author,
              labels,
            } = version;

            return (
              <Tab
                key={id}
                className={classNames(
                  "group w-full select-none overflow-x-hidden outline-none",
                  Number(versionNumber) === number ? "bg-blue-100" : "",
                )}
              >
                <Link
                  to={`/workspace/${workspaceId}/workflow/${workflowId}/version/${number}`}
                  className="relative block"
                >
                  <div className="relative mx-6 border-l border-gray-300 p-2 text-start">
                    <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-gray-500 bg-white"></div>
                    <div className="mx-6 flex items-center justify-between">
                      <div className="flex-shrink-0">
                        <div className="text-start font-medium">{`Version ${number}`}</div>
                      </div>
                    </div>
                    <div className="ml-6 flex items-center justify-between">
                      <div className="truncate overflow-ellipsis py-0.5 text-xs text-gray-500">
                        <span>{moment(created_at).fromNow()}</span>
                        {author && (
                          <>
                            <span className="px-1 text-gray-400">•</span>
                            <User className="inline h-3 w-auto pr-1" />
                            <span className="truncate overflow-ellipsis">
                              {author}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    {commit_message && (
                      <div className="ml-6 flex items-center justify-between">
                        <div className="py-0.5 text-xs text-gray-600">
                          <ChatIcon
                            className="mr-1 inline h-4 w-auto pb-0.5"
                            aria-hidden="true"
                          />
                          {commit_message}
                        </div>
                      </div>
                    )}
                    {labels && labels.length > 0 && (
                      <div className="ml-6 flex items-center justify-between">
                        <div className="flex flex-wrap gap-1 py-0.5 text-xs">
                          {labels.map((label) => (
                            <div
                              key={`${label.name}-${label.id}`}
                              className={classNames(
                                "group/Label relative rounded-sm border px-2 py-0.5 text-xs font-medium",
                                Number(versionNumber) === number
                                  ? "border-gray-400 bg-blue-50 text-gray-600"
                                  : "border-gray-200 text-gray-500",
                                "hover:bg-red-100 hover:text-red-400",
                                Number(versionNumber) === number
                                  ? "hover:border-red-300"
                                  : "hover:border-red-200",
                              )}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              {label.name}
                              <div
                                className="absolute -right-1.5 -top-1.5"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleDeleteReleaseLabel(label.id, id);
                                }}
                              >
                                <XIcon className="hidden h-3 w-3 cursor-pointer text-red-400 group-hover/Label:block" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="absolute right-2 top-6 hidden -translate-y-1/2 group-hover:flex">
                    <WorkflowAddReleaseLabelButton
                      workflow={workflowRef.current!}
                      versionId={id}
                      refreshVersions={refreshVersions}
                    />
                  </div>
                </Link>
              </Tab>
            );
          })}
        </Tab.List>
      </div>
    </Tab.Group>
  );
};

export default WorkflowVersionSelector;
