import { ResourceHeader } from "@/components/ResourceHeader";

import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useEditPromptTemplate } from "@/queries";
import { BasePromptTemplate, ListPromptVersion } from "@/types";
import { PromptRegistry } from "@/types/prompt-registry";
import { PencilIcon, PlayIcon, TrashIcon } from "@heroicons/react/outline";
import { Stethoscope } from "lucide-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface PromptTemplateHeaderProps {
  generatePlaygroundUrl: () => string;
  prompt: PromptRegistry;
  promptId: string;
  promptName: string;
  promptTemplateVersions: ListPromptVersion[]; // Replace 'any' with the actual type of the templates
  setOpenDeleteForm: (open: boolean) => void;
  setShowVersionList: (show: boolean) => void;
  showVersionList: boolean;
  templateFormat: string;
  version?: ListPromptVersion;
  total: number;
  templateTags: Array<string>;
  isSnippetAvailable?: boolean;
  isXrayMode?: boolean;
  setIsXrayMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PromptTemplateHeader: React.FC<PromptTemplateHeaderProps> = ({
  generatePlaygroundUrl,
  prompt,
  promptId,
  promptName,
  promptTemplateVersions,
  setOpenDeleteForm,
  setShowVersionList,
  showVersionList,
  templateFormat,
  version,
  total,
  templateTags,
  isSnippetAvailable = false,
  isXrayMode = false,
  setIsXrayMode,
}) => {
  const navigate = useNavigate();
  const authContext = useAuth();
  const userContext = useUser();
  const editPromptTemplate = useEditPromptTemplate();

  const handleEditPromptTemplate = (basePromptTemplate: BasePromptTemplate) => {
    editPromptTemplate.mutate({
      prompt_id: Number(promptId),
      userToken: authContext?.userToken!,
      basePromptTemplate,
      activeWorkspaceId: userContext?.activeWorkspaceId!,
    });
  };

  const actions = [
    {
      label: "Delete",
      icon: <TrashIcon className="h-4 w-auto pr-1" aria-hidden="true" />,
      onClick: () => setOpenDeleteForm(true),
      variant: "destructiveOutline" as const,
    },
    {
      label: "Playground",
      icon: <PlayIcon className="h-4 w-auto pr-1" aria-hidden="true" />,
      onClick: () => navigate(generatePlaygroundUrl()),
      variant: "secondaryOutline" as const,
    },
    ...(isSnippetAvailable
      ? [
          {
            label: "X-Ray",
            icon: (
              <Stethoscope className="h-4 w-auto pr-1" aria-hidden="true" />
            ),
            onClick: () => setIsXrayMode((prev: boolean) => !prev),
            variant: isXrayMode ? "filled" : ("outline" as any),
          },
        ]
      : []),
    {
      label: "Edit",
      icon: <PencilIcon className="h-4 w-auto pr-1" aria-hidden="true" />,
      onClick: () =>
        navigate(
          `/workspace/${userContext?.activeWorkspaceId}/prompt/${promptId}/${version?.number}/edit`,
        ),
      variant: "outline" as const,
    },
  ];

  return (
    <ResourceHeader
      title={promptName}
      onTitleEdit={(prompt_name) =>
        handleEditPromptTemplate({ prompt_name, tags: prompt.tags })
      }
      id={promptId}
      createdAt={
        version?.created_at ? moment(version.created_at).fromNow() : ""
      }
      versionInfo={{ current: version?.number || 1, total }}
      format={templateFormat}
      tags={prompt.tags}
      onTagsEdit={(tags) =>
        handleEditPromptTemplate({ prompt_name: promptName, tags })
      }
      actions={actions}
      showVersions={showVersionList}
      onToggleVersions={() => setShowVersionList(!showVersionList)}
    />
  );
};
