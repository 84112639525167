import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { ColumnType, ColumnTypeIcon } from "@/types/evaluate";
import { useReactFlow } from "@xyflow/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { WorkflowNodeType } from "../../types";
import { formatRawNodeType } from "../../utils";
import { useWorkflow } from "../workflow-context";

const options = Object.values(ColumnType).filter(
  (type) => type !== ColumnType.HUMAN && type !== ColumnType.DATASET,
);

// Categorize options
const categories = {
  "Data Sources": options.filter((type) =>
    [
      ColumnType.DATASET,
      ColumnType.PROMPT_TEMPLATE,
      ColumnType.ENDPOINT,
      ColumnType.HUMAN,
    ].includes(type),
  ),
  "Data Processing": options.filter((type) =>
    [
      ColumnType.COMPARE,
      ColumnType.COALESCE,
      ColumnType.ABSOLUTE_NUMERIC_DISTANCE,
      ColumnType.REGEX,
      ColumnType.JSON_PATH,
      ColumnType.PARSE_VALUE,
      ColumnType.CONTAINS,
      ColumnType.VARIABLE,
      ColumnType.XML_PATH,
    ].includes(type),
  ),
  Analytical: options.filter((type) =>
    [
      ColumnType.LLM_ASSERTION,
      ColumnType.ASSERT_VALID,
      ColumnType.COSINE_SIMILARITY,
      ColumnType.COUNT,
      ColumnType.MATH_OPERATOR,
    ].includes(type),
  ),
};

// Update the node in react-flow
const NodeTypeSelector = () => {
  const { activeNode } = useWorkflow();
  const { updateNode } = useReactFlow();
  if (!activeNode) return null;

  const onSelect = (value: string) => {
    if (!activeNode) return;

    runInAction(() =>
      updateNode(activeNode.id, (node) => ({
        ...node,
        type: "basic",
        data: {
          type: value as WorkflowNodeType,
          label: activeNode.name,
          unsaved: true,
        },
      })),
    );
  };

  const currentType = activeNode.node_type;
  const SelectedTypeIcon =
    currentType &&
    (ColumnTypeIcon[currentType as unknown as ColumnType] as React.ElementType);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="mb-4 rounded-md border px-4 py-2 transition-colors duration-200 hover:bg-gray-100">
        <div className="inline-flex items-center space-x-2">
          {SelectedTypeIcon && <SelectedTypeIcon className="h-5 w-5" />}
          <span>{formatRawNodeType(currentType)}</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {Object.entries(categories).map(([category, types]) => (
          <div key={category}>
            <DropdownMenuLabel>{category}</DropdownMenuLabel>
            {types.map((option) => {
              const TypeIcon =
                option &&
                (ColumnTypeIcon[
                  option as unknown as ColumnType
                ] as React.ElementType);

              return (
                <DropdownMenuItem
                  key={option}
                  onSelect={() => onSelect(option)}
                  className="flex w-full items-center space-x-2"
                >
                  <TypeIcon className="h-5 w-5" />
                  <span>{formatRawNodeType(option)}</span>
                </DropdownMenuItem>
              );
            })}
            <DropdownMenuSeparator />
          </div>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default observer(NodeTypeSelector);
