import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ParseValueConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";

const ParseValueBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [type, setType] = useState<string | null>(
    columnData.configuration?.type ?? null,
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.column || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    type: boolean;
    column: boolean;
  }>({
    name: true,
    type: true,
    column: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, type: true, column: true });
      return;
    } else if (!type) {
      setDataIsValid({ name: true, type: false, column: true });
      return;
    } else if (!column) {
      setDataIsValid({ name: true, type: true, column: false });
      return;
    }

    setDataIsValid({ name: true, type: true, column: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        type,
        column,
      } as ParseValueConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="flex flex-col space-y-4">
        <div>
          <div className="text-lg font-semibold">Configure Parse Value</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will parse a value from a column into the desired type.
          </div>
        </div>
        <div
          style={{ display: bandaid ? "none" : "flex" }}
          className="items-center"
        >
          <label htmlFor="name" className="mr-4 w-1/3 text-left">
            Column name:
          </label>
          <input
            id="name"
            className={`w-2/3 rounded border ${
              !dataIsValid.name ? "border-red-500" : "border-gray-300"
            } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
            value={name || ""}
            onChange={(e) => setName(e.target.value)}
            disabled={!editable}
          />
        </div>
        {!dataIsValid.name && (
          <div className="flex">
            <div className="w-1/3">&nbsp;</div>
            <div className="w-2/3 text-left text-xs text-red-500">
              Name is required
            </div>
          </div>
        )}

        <div className="flex items-center">
          <label htmlFor="type" className="mr-4 w-1/3 text-left">
            Type:
          </label>
          <div className="w-2/3">
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full">
                {types.find((t) => t.value === type)?.label || (
                  <span className="font-normal text-gray-500">
                    Select a type...
                  </span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {types.map((type) => (
                  <DropdownMenuItem
                    key={type.value}
                    onSelect={() => setType(type.value)}
                  >
                    {type.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        {!dataIsValid.type && (
          <div className="flex">
            <div className="w-1/3">&nbsp;</div>
            <div className="w-2/3 text-left text-xs text-red-500">
              Type is required
            </div>
          </div>
        )}

        <div className="flex items-center">
          <label htmlFor="column" className="mr-4 w-1/3 text-left">
            Column:
          </label>
          <div className="w-2/3">
            <DropdownMenu>
              <DropdownMenuTrigger disabled={!editable} className="w-full">
                {column || (
                  <span className="font-normal text-gray-500">
                    Select a column...
                  </span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {availableColumns.map((column) => (
                  <DropdownMenuItem
                    key={column.name}
                    onSelect={() => setColumn(column.name)}
                  >
                    {formatInputVariable(column.column_type, column.name)}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        {!dataIsValid.column && (
          <div className="flex">
            <div className="w-1/3">&nbsp;</div>
            <div className="w-2/3 text-left text-xs text-red-500">
              Column is required
            </div>
          </div>
        )}
      </div>
    </ModalStep>
  );
};

const types = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "boolean", label: "Boolean" },
  { value: "object", label: "JSON" },
] as const;

export default ParseValueBuilder;
